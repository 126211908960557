import { useContext, useEffect, useState } from 'react';
import { CircularProgress, Dialog, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { toast } from 'sonner';
import './index.css';
import type FirewallRule from '../../interfaces/firewallRule';
import type SecurityGroup from '../../interfaces/securityGroup';
import { UserContext } from '../../contexts/UserContext';

type FirewallRuleInput = {
  protocol: string;
  fromPort: number;
  toPort: number;
  cidrIp: number;
  securityGroupId: number;
};

function Firewall() {
  const [openAddRuleModal, setOpenAddRuleModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDeleteFirewallRuleModal, setOpenDeleteFirewallRuleModal] =
    useState<boolean>(false);
  const [expandedGroups, setExpandedGroups] = useState<number[]>([]);
  const [deleteFirewallRuleId, setDeleteFirewallRuleId] = useState<number>(0);
  const [securityGroups, setSecurityGroups] = useState<[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ipPart1, setIpPart1] = useState('');
  const [ipPart2, setIpPart2] = useState('');
  const [ipPart3, setIpPart3] = useState('');
  const [ipPart4, setIpPart4] = useState('');
  const [subnet, setSubnet] = useState('');

  const authToken = window.localStorage.getItem('authToken');
  const { user } = useContext(UserContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<FirewallRuleInput>();

  const handleIpChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const value = e.target.value;
    const isValid = /^[0-9]*$/.test(value);

    if (
      isValid &&
      !Number.isNaN(Number(value)) &&
      Number(value) >= 0 &&
      Number(value) <= 255
    ) {
      setter(value);
    }
  };

  const toggleExpand = (groupId: number) => {
    if (expandedGroups.includes(groupId)) {
      setExpandedGroups(expandedGroups.filter((id) => id !== groupId));
    } else {
      setExpandedGroups([...expandedGroups, groupId]);
    }
  };

  const handleSubnetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const isValid = /^[0-9]*$/.test(value);

    if (
      isValid &&
      !Number.isNaN(Number(value)) &&
      Number(value) >= 0 &&
      Number(value) <= 32
    ) {
      setSubnet(value);
    }
  };

  const addFirewallRule = async (data: FirewallRuleInput) => {
    setIsLoading(true);
    const cidrIP = `${ipPart1}.${ipPart2}.${ipPart3}.${ipPart4}/${subnet}`;

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/firewall-rules`,
        {
          ...data,
          cidrIP,
          securityGroupId: Number(data.securityGroupId),
          toPort: Number(data.toPort),
          fromPort: Number(data.fromPort),
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setOpenAddRuleModal(false);
        fetchFirewallRules();
      })
      .catch((err) => {
        setOpenAddRuleModal(false);
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchFirewallRules = async () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/security-groups`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        setSecurityGroups(res.data.data);
        if (res.data.data.length > 0) {
          setExpandedGroups([res.data.data[0].id]);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const deleteFirewallRule = async (id: number) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/firewall-rules/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        fetchFirewallRules();
        setOpenDeleteFirewallRuleModal(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setOpenDeleteFirewallRuleModal(false);
      });
  };

  useEffect(() => {
    fetchFirewallRules();
    return () => {
      reset();
    };
  }, []);

  return (
    <div>
      <div className="flex justify-between">
        <div className="lg:text-[40px] md:text-[26px] sm:text-[20px] text-[20px]">
          Firewall Rules
        </div>
        {user.role === 'ADMIN' && (
          <button
            className="max-h-[47px] lg:text-[16px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[5px] lg:px-5 md:px-4 sm:px-3 px-1 border-[#4E00FF] bg-[#8B5CF6]"
            onClick={() => setOpenAddRuleModal(true)}
          >
            Add Rule
          </button>
        )}
      </div>
      <Dialog
        open={openAddRuleModal}
        onClose={() => setOpenAddRuleModal(false)}
        aria-labelledby="responsive-dialog-title"
        sx={{
          '.css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
            borderRadius: '20px',
            backgroundColor: '#000',
            border: '2px solid #8B5CF6',
          },
          '.MuiPaper-root': {
            scrollbarWidth: 'thin',
          },
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className="text-white text-center"
        >
          {'Add Firewall Rule'}
        </DialogTitle>
        <div className="mx-10">
          <form
            onSubmit={handleSubmit(addFirewallRule)}
            className="grid justify-center w-full"
          >
            <div className="grid gap-2">
              <div className="grid text-white">
                <label className="text-[#9e9c9c]">IP Protocol</label>
                <select
                  id="protocol"
                  {...register('protocol', {
                    required: 'This field is required',
                  })}
                  defaultValue="All"
                  className="custom-small:w-[350px] w-[250px] p-3 bg-black outline outline-[#505050] rounded-[5px]"
                >
                  <option value="all">All</option>
                  <option value="udp">UDP</option>
                  <option value="tcp">TCP</option>
                </select>
                {errors.protocol && <span>{errors.protocol.message}</span>}
              </div>
              <div className="grid text-white">
                <label className="text-[#9e9c9c]">Security Group</label>
                <select
                  id="securityGroupId"
                  {...register('securityGroupId', {
                    required: 'This field is required',
                  })}
                  className="custom-small:w-[350px] w-[250px] p-3 bg-black outline outline-[#505050] rounded-[5px]"
                >
                  {securityGroups.map((group: SecurityGroup) => (
                    <option key={group.id} value={group.id}>
                      ({group.region})
                    </option>
                  ))}
                </select>
                {errors.securityGroupId && (
                  <span>{errors.securityGroupId.message}</span>
                )}
              </div>
              <div className="grid text-white">
                <label className="text-[#9e9c9c]">From Port</label>
                <input
                  type="number"
                  id="fromPort"
                  {...register('fromPort', {
                    required: 'This field is required',
                    min: { value: 0, message: 'Must be a non-negative number' },
                    max: { value: 65535, message: 'Must be a valid port' },
                  })}
                  className="custom-small:w-[350px] w-[250px] p-3 bg-black outline outline-[#505050] rounded-[5px]"
                />
                {errors.fromPort && (
                  <span className="custom-small:w-[350px] w-[250px] text-red-600">
                    {errors.fromPort.message}
                  </span>
                )}
              </div>
              <div className="grid text-white">
                <label className="text-[#9e9c9c]">To Port</label>
                <input
                  type="number"
                  id="toPort"
                  {...register('toPort', {
                    required: 'This field is required',
                    min: { value: 0, message: 'Must be a non-negative number' },
                    max: { value: 65535, message: 'Must be a valid port' },
                    validate: (value) =>
                      value >= getValues('fromPort') ||
                      'To Port must be equal to or greater than From Port',
                  })}
                  className="custom-small:w-[350px] w-[250px] p-3 bg-black outline outline-[#505050] rounded-[5px]"
                />
                {errors.toPort && (
                  <span className="custom-small:w-[350px] w-[250px] text-red-600">
                    {errors.toPort.message}
                  </span>
                )}
              </div>
              <div className="grid text-white">
                <label className="text-[#9e9c9c]">CIDR IP</label>
                <div className="sm:flex items-center">
                  <input
                    type="text"
                    value={ipPart1}
                    onChange={(e) => handleIpChange(e, setIpPart1)}
                    className="sm:w-12 w-8 h-8 sm:h-12 text-center bg-black outline outline-[#505050] rounded-[5px]"
                    max="255"
                    min="0"
                    required
                  />
                  <span className="text-white px-2">.</span>
                  <input
                    type="text"
                    value={ipPart2}
                    onChange={(e) => handleIpChange(e, setIpPart2)}
                    className="sm:w-12 w-8 h-8 sm:h-12 text-center bg-black outline outline-[#505050] rounded-[5px]"
                    max="255"
                    min="0"
                    required
                  />
                  <span className="text-white px-2">.</span>
                  <input
                    type="text"
                    value={ipPart3}
                    onChange={(e) => handleIpChange(e, setIpPart3)}
                    className="sm:w-12 w-8 h-8 sm:h-12 text-center bg-black outline outline-[#505050] rounded-[5px]"
                    max="255"
                    min="0"
                    required
                  />
                  <span className="text-white px-2">.</span>
                  <input
                    type="text"
                    value={ipPart4}
                    onChange={(e) => handleIpChange(e, setIpPart4)}
                    className="sm:w-12 w-8 h-8 sm:h-12 text-center bg-black outline outline-[#505050] rounded-[5px]"
                    max="255"
                    min="0"
                    required
                  />
                  <span className="text-white px-2">/</span>
                  <input
                    type="text"
                    value={subnet}
                    onChange={handleSubnetChange}
                    className="sm:w-12 w-8 h-8 sm:h-12 text-center bg-black outline outline-[#505050] rounded-[5px]"
                    max="32"
                    min="0"
                    required
                  />
                </div>
                {errors.cidrIp && (
                  <span className="custom-small:w-[350px] w-[250px] text-red-600">
                    {errors.cidrIp.message}
                  </span>
                )}
              </div>
              <div className="flex justify-center gap-5 w-full">
                <button className="bg-[#8B5CF6] w-2/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px] font-bold">
                  {isLoading ? (
                    <div className="flex justify-center items-center">
                      <div className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full border-white border-t-transparent mr-2"></div>
                    </div>
                  ) : (
                    'Add'
                  )}
                </button>
                <button
                  className="bg-[#8B5CF6] text-white w-2/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px]"
                  onClick={() => setOpenAddRuleModal(false)}
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </Dialog>
      <Dialog
        open={openDeleteFirewallRuleModal}
        onClose={() => setOpenDeleteFirewallRuleModal(false)}
        aria-labelledby="responsive-dialog-title"
        sx={{
          '.css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
            borderRadius: '20px',
            backgroundColor: '#000',
            border: '2px solid #8B5CF6',
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title" className="text-white">
          {'Are you sure you want to delete this rule?'}
        </DialogTitle>
        <div className="flex justify-center gap-5">
          <button
            className="bg-[#8B5CF6]  w-1/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px] text-red-700 font-bold"
            onClick={() => deleteFirewallRule(deleteFirewallRuleId)}
          >
            <DeleteIcon />
            Yes
          </button>
          <button
            className="bg-[#8B5CF6] text-white w-1/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px]"
            onClick={() => setOpenDeleteFirewallRuleModal(false)}
          >
            No
          </button>
        </div>
      </Dialog>
      {loading ? (
        <div className="flex w-full justify-center py-20">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div>
          {securityGroups.length > 0 ? (
            securityGroups.map((group: SecurityGroup) => (
              <div
                key={group.id}
                className="security-group border border-[#8B5CF6] my-2 rounded-[10px] select-none"
              >
                <div
                  className="group-header flex justify-between px-3 py-5 items-center custom-small:px-2 cursor-pointer"
                  onClick={() => toggleExpand(group.id)}
                >
                  <div className="font-['Poppins'] flex sm:gap-5 gap-10 custom-small:text-[14px] text-[16px] font-[600] text-white select-none">
                    <span>{group.name}</span>
                    <span className="text-[#ADADAD] w-[105px]">
                      ({group.region})
                    </span>
                  </div>
                  <div className="text-white text-[14px]">
                    {expandedGroups.includes(group.id) ? '▲' : '▼'}
                  </div>
                </div>
                {expandedGroups && expandedGroups.includes(group.id) && (
                  <div
                    className={`firewall-rules-container p-4 overflow-hidden transition-max-height duration-[5.5s] ease-in-out ${
                      expandedGroups.includes(group.id)
                        ? 'max-h-[1000px]'
                        : 'max-h-0'
                    }`}
                  >
                    {group.firewallRules.length > 0 ? (
                      group.firewallRules.map((rule: FirewallRule) => (
                        <div className="firewall-rule-card my-2">
                          <div
                            className="instance-box border my-2"
                            key={rule.id}
                          >
                            <div className="flex justify-between py-5 items-center custom-small:px-2">
                              <div className="md:grid-cols-4 grid-cols-2 grid md:gap-8 sm:gap-5 gap-4 font-['Poppins'] custom-small:text-[12px] text-[8px] font-[600] text-[#ADADAD]">
                                <div className="grid">
                                  <div className="text-[16px]">IP Protocol</div>
                                  <div className="text-white font-thin">
                                    {rule.protocol.toUpperCase()}
                                  </div>
                                </div>
                                <div className="grid">
                                  <div className="text-[16px]">From Port</div>
                                  <div className="text-white font-thin">
                                    {rule.fromPort}
                                  </div>
                                </div>
                                <div className="grid">
                                  <div className="text-[16px]">To Port</div>
                                  <div className="text-white font-thin">
                                    {rule.toPort}
                                  </div>
                                </div>
                                <div className="grid">
                                  <div className="text-[16px]">Cidr IP</div>
                                  <div className="text-white font-thin">
                                    {rule.cidrIP}
                                  </div>
                                </div>
                              </div>
                              {user?.role === 'ADMIN' && (
                                <div className="md:flex md:gap-2 w-min">
                                  <button
                                    className={`delete-button custom-small:h-[33px] h-[24px] custom-small:max-w-[122px] w-[65px] md:text-[16px] custom-small:text-[12px] text-[10px] custom-small:rounded-[12px] rounded-[6px]`}
                                    onClick={() => {
                                      setOpenDeleteFirewallRuleModal(true);
                                      setDeleteFirewallRuleId(rule.id);
                                    }}
                                  >
                                    Delete
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-red-500 text-center">
                        No firewall rules available.
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="text-red-500 text-center">
              No security groups found. Please start an instance first.
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Firewall;
