import React, { useContext, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import { toast } from 'sonner';
import { UserContext } from '../../contexts/UserContext';

const CheckoutForm = ({
  credits,
  setOpenStripe,
}: {
  credits: number | null;
  setOpenStripe: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useContext(UserContext);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/payments/create-intent`,
      {
        amount: credits ? credits * 10 : 0,
      },
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('authToken')}`,
        },
      }
    );

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentIntent } = await stripe.confirmCardPayment(
      res.data.data.clientSecret,
      {
        payment_method: {
          card: cardElement!,
        },
      }
    );

    if (error) {
      toast.error(error.message);
      setLoading(false);
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/payments/complete-purchase`,
          {
            paymentIntentId: paymentIntent.id,
          },
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem('authToken')}`,
            },
          }
        )
        .then((res) => {
          toast.success(res.data.message);
          setUser({ ...user, credits: res.data.data.credits });
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
      setLoading(false);
    }

    setOpenStripe(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement
        options={{ style: { base: { fontSize: '16px', color: '#fff' } } }}
      />
      <button
        className="w-full text-black bg-[#8B5CF6] mt-5 h-[55px] rounded-[17px] text-[20px] font-['Poppins'] font-[600]"
        type="submit"
      >
        {loading ? 'Processing...' : 'Pay'}
      </button>
    </form>
  );
};

export default CheckoutForm;
