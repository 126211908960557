import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'sonner';
import { useForm, SubmitHandler } from 'react-hook-form';
import Logo from '../../assets/Logo.png';
import Background from '../../assets/Background.png';
import awsLogo from '../../assets/aws.png';
import coinbase from '../../assets/coinbase.png';
import coingecko from '../../assets/coingecko.png';
import gc from '../../assets/gc.png';
import ibm from '../../assets/ibm.png';
import nvidia from '../../assets/nvidia.png';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';

type Inputs = {
  teamName: string;
  name: string;
  username: string;
  phoneNumber: string;
  email: string;
  password: string;
  agreement: boolean;
};

function Signup() {
  const [agreementStatus, setAgreementStatus] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSignUpSubmit: SubmitHandler<Inputs> = (data) => {
    const { teamName, name, username, phoneNumber, email, password } = data;

    const requestBody: {
      teamName: string;
      name: string;
      username: string;
      email: string;
      password: string;
      phoneNumber?: string;
    } = {
      teamName,
      name,
      username,
      email,
      password,
      ...(phoneNumber && { phoneNumber }),
    };

    axios
      .post(process.env.REACT_APP_API_URL + '/users/signup', requestBody)
      .then((res) => {
        toast.success(res.data.message);
        navigate('/login');
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      navigate('/homepage/dashboard');
    }
  }, []);

  return (
    <div>
      <div className="w-full h-screen fixed bg-black aspect-video	">
        <img src={Background} alt="background" />
      </div>
      <div className="w-full absolute h-screen">
        <div className="flex justify-between sm:p-10 p-5">
          <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={() => navigate('/')}
          >
            <img src={Logo} alt="logo" />
            <h1 className="text-white md:text-[26px] text-[12px] font-[400]">
              <strong>Deploy</strong>.paal
            </h1>
          </div>
          <div
            className="flex items-center gap-3 justify-center cursor-pointer"
            onClick={() => navigate('/login')}
          >
            <h1 className="text-[#8B5CF6] md:text-[20px] text-[16px]">
              Sign in
            </h1>
            <Arrow />
          </div>
        </div>
        <div className="md:flex gap-3 w-full md:text-start text-center">
          <div className="w-full items-center flex justify-center">
            <div className="w-3/4 ">
              <div className="text-[#838383] xl:text-[45px] md:text-[30px] text-[22px] font-[400] lg:mx-24 md:mx-10 mx-5 ">
                Train and infer AI models faster{' '}
                <strong className="text-white">with our powerful GPU</strong>
              </div>
              <div className="grid grid-cols-6 md:grid-cols-3 gap-3 py-5 xl:px-24 lg:px-24 md:px-10 px-5">
                <img src={gc} alt="logo" />
                <img src={ibm} alt="logo" />
                <img src={coingecko} alt="logo" />
                <img src={nvidia} alt="logo" />
                <img src={awsLogo} alt="logo" />
                <img src={coinbase} alt="logo" />
              </div>
            </div>
          </div>
          <div className="text-white w-full flex h-fit sm:items-center md:justify-start justify-center">
            <form
              className="w-3/4 md:p-0 p-5"
              onSubmit={handleSubmit(onSignUpSubmit)}
            >
              <h1 className="xl:text-[40px] md:text-[30px] sm:text-[24px] font-[400]">
                Create your free account
              </h1>
              <input
                type="text"
                placeholder="Team Name"
                className="w-full bg-none border-b-2 outline-none pt-5 text-[#838383] bg-[#0000] sm:text-[16px] text-[14px]"
                {...register('teamName', {
                  required: true,
                })}
              />
              <input
                type="text"
                placeholder="Full Name"
                className="w-full bg-none border-b-2 outline-none pt-14 text-[#838383] bg-[#0000] sm:text-[16px] text-[14px]"
                {...register('name', {
                  required: true,
                })}
              />
              <input
                type="text"
                placeholder="Username"
                className="w-full bg-none border-b-2 outline-none pt-14 text-[#838383] bg-[#0000] sm:text-[16px] text-[14px]"
                {...register('username', {
                  required: true,
                })}
              />
              <input
                type="number"
                placeholder="Phone Number (optional)"
                className="w-full bg-none border-b-2 outline-none pt-14 text-[#838383] bg-[#0000] sm:text-[16px] text-[14px]"
                {...register('phoneNumber', {
                  required: false,
                })}
              />
              <input
                type="text"
                placeholder="Email"
                className="w-full bg-none border-b-2 outline-none pt-14 text-[#838383] bg-[#0000] sm:text-[16px] text-[14px]"
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                    message: 'Please enter a valid email address',
                  },
                })}
              />
              {errors.email && (
                <p className="text-red-500">{errors.email?.message}</p>
              )}
              <input
                type="password"
                placeholder="Password"
                className="w-full bg-none border-b-2 outline-none pt-14 text-[#838383] bg-[#0000] sm:text-[16px] text-[14px]"
                {...register('password', {
                  required: true,
                })}
              />
              <p className="text-[#838383] mt-10 md:text-[16px] text-[10px] text-start flex items-center">
                <input
                  type="checkbox"
                  className="accent-black mr-2"
                  {...register('agreement', {
                    required: true,
                  })}
                  onChange={(e) => setAgreementStatus(!agreementStatus)}
                />
                I agree to Deploy.paal's Privacy Policy, Terms of Service, and
                Acceptable Use Policy
              </p>
              <button
                className="w-full bg-[#8B5CF6] text-black p-3 mt-5 rounded-xl font-[500]"
                type="submit"
              >
                Sign up
              </button>
              <p className="text-[#838383] mt-10 md:text-[16px] text-[10px]">
                By adding my phone number, I agree that Lambda may contact me by
                phone or text. Frequency varies. Message and data rates may
                apply. Lambda's Terms of Use & Privacy Policy apply.
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
