import { useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { CircularProgress } from '@mui/material';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import axios from 'axios';
import { toast } from 'sonner';
import { ReactComponent as RunningIcon } from '../../assets/active-icon.svg';
import { ReactComponent as StoppedIcon } from '../../assets/stopped-icon.svg';
import { ReactComponent as PendingIcon } from '../../assets/pending-icon.svg';
import Grid from '../../assets/Grid.png';
import CreateInstance from './createInstance';
import ProtectedRoute from '../ProtectedRoute';
import { UserContext } from '../../contexts/UserContext';
import Instance from '../../interfaces/instances';
import './index.css';

function Instances() {
  const [expandedInstances, setExpandedInstances] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [refetchInstances, setRefetchInstances] = useState<boolean>(true);
  const [userInstances, setUserInstances] = useState<Instance[] | null>(null);

  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const toggleInstanceDetails = (id: string) => {
    if (expandedInstances.includes(id)) {
      setExpandedInstances(
        expandedInstances.filter((instanceId) => instanceId !== id)
      );
    } else {
      setExpandedInstances([...expandedInstances, id]);
    }
  };

  const getUserInstances = async () => {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_API_URL + '/instances', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      })
      .then((res) => {
        setUserInstances(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const startOrStopInstance = async (id: string, state: string) => {
    setLoading(true);
    axios
      .post(
        process.env.REACT_APP_API_URL +
          `/instances/${id}/${state === 'start' ? 'start' : 'stop'}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        getUserInstances();
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  const deleteInstance = async (id: string) => {
    setLoading(true);
    axios
      .delete(process.env.REACT_APP_API_URL + `/instances/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        getUserInstances();
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  useEffect(() => {
    getUserInstances();
  }, [refetchInstances]);

  return (
    <div className="pt-5">
      <Routes>
        <Route
          path="/"
          element={
            <div
              className="min-h-screen bg-cover bg-center bg-no-repeat py-5"
              style={{
                backgroundImage: `radial-gradient(circle, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 70%), url(${Grid})`,
              }}
            >
              <div className="flex justify-between">
                <div className="lg:text-[40px] md:text-[26px] sm:text-[20px] text-[20px]">
                  GPU Instances
                </div>
                {user.role === 'ADMIN' && (
                  <div className="flex md:gap-4 gap-2">
                    <button className="max-h-[47px] lg:text-[16px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[5px] lg:px-5 md:px-4 sm:px-3 px-1 border-[#8B5CF6]">
                      Request instance
                    </button>
                    <button
                      className="max-h-[47px] lg:text-[16px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[5px] lg:px-5 md:px-4 sm:px-3 px-1 border-[#4E00FF] bg-[#8B5CF6]"
                      onClick={() =>
                        navigate('/homepage/instances/create-instance')
                      }
                    >
                      Launch instance
                    </button>
                  </div>
                )}
              </div>
              {loading ? (
                <div className="flex w-full justify-center py-20">
                  <CircularProgress color="secondary" />
                </div>
              ) : userInstances !== null && userInstances.length > 0 ? (
                userInstances.map((instance) => {
                  const date = new Date(instance.expirationTime);

                  return (
                    <div className="py-5" key={instance.id}>
                      <div className="instance-box border border-[#ffffff99]">
                        <div className="header font-['Poppins'] md:text-[20px] sm:text-[16px] text-[12px]">
                          <div>{instance.name}</div>
                          <div className="gap-2 flex items-center">
                            {instance.state === 'running' ? (
                              <>
                                <RunningIcon />
                                <div className="capitalize">
                                  {instance.state}
                                </div>
                              </>
                            ) : instance.state === 'stopped' ? (
                              <>
                                <StoppedIcon />
                                <div className="capitalize">
                                  {instance.state}
                                </div>
                              </>
                            ) : (
                              <>
                                <PendingIcon />
                                <div className="capitalize">
                                  {instance.state === 'shutting-down'
                                    ? 'Shutting Down'
                                    : instance.state}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="lg:flex grid gap-3 justify-between py-5 items-center custom-small:px-2">
                          <div className="grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-3 gap-2 font-['Poppins'] custom-small:text-[12px] text-[8px] font-[600] text-[#ADADAD]">
                            <div className="grid">
                              <div>VRAM / GPU</div>
                              <div>{instance.gpuDetails?.gpu} GB</div>
                            </div>
                            <div className="grid">
                              <div>vCPUs</div>
                              <div>{instance.gpuDetails?.vCPUs}</div>
                            </div>
                            <div className="grid">
                              <div>RAM</div>
                              <div>{instance.gpuDetails?.memoryGiB} GiB</div>
                            </div>
                            <div className="grid">
                              <div>STORAGE</div>
                              <div>
                                {instance.gpuDetails?.instanceStorageGB}
                              </div>
                            </div>
                            <div className="grid">
                              <div>PRICE</div>
                              {instance.gpuDetails?.price && (
                                <div>
                                  {parseFloat(
                                    instance?.gpuDetails?.price.toString()
                                  ).toFixed(2)}{' '}
                                  Cr / hr
                                </div>
                              )}
                            </div>
                            <div className="grid">
                              <div>Expiration Time</div>
                              <div>{date.toLocaleString('en-US')}</div>
                            </div>
                          </div>
                          <div className="flex gap-2 justify-center">
                            {instance.state === 'running' ? (
                              user.role !== 'VIEWER' && (
                                <button
                                  className="stop-button custom-small:h-[33px] h-[24px] custom-small:max-w-[122px] w-[65px] md:text-[16px] custom-small:text-[12px] text-[10px] custom-small:rounded-[12px] rounded-[6px]"
                                  onClick={() =>
                                    startOrStopInstance(instance.id, 'stop')
                                  }
                                >
                                  Stop
                                </button>
                              )
                            ) : instance.state === 'stopped' ? (
                              <button
                                className="start-button custom-small:h-[33px] h-[24px] custom-small:max-w-[122px] w-[65px] md:text-[16px] custom-small:text-[12px] text-[10px] custom-small:rounded-[12px] rounded-[6px]"
                                onClick={() =>
                                  startOrStopInstance(instance.id, 'start')
                                }
                              >
                                Start
                              </button>
                            ) : null}
                            {user.role === 'ADMIN' && (
                              <button
                                className={`delete-button custom-small:h-[33px] h-[24px] custom-small:max-w-[122px] w-[65px] md:text-[16px] custom-small:text-[12px] text-[10px] custom-small:rounded-[12px] rounded-[6px] ${instance.state === 'running' || instance.state === 'stopped' ? '' : 'hidden'}`}
                                onClick={() => deleteInstance(instance.id)}
                              >
                                Delete
                              </button>
                            )}
                          </div>
                        </div>
                        <div
                          className={`flex justify-center cursor-pointer ${instance.state !== 'running' && 'hidden'}`}
                        >
                          <ArrowDownwardIcon
                            onClick={() => toggleInstanceDetails(instance.id)}
                            className={`transition-transform duration-300 ${
                              expandedInstances.includes(instance.id)
                                ? 'rotate-180'
                                : ''
                            }`}
                          />
                        </div>
                        <div
                          className={`flex gap-5 items-center overflow-hidden transition-all duration-500 ease-in-out ${instance.state !== 'running' && 'hidden'} ${
                            expandedInstances.includes(instance.id)
                              ? 'max-h-40 opacity-100'
                              : 'max-h-0 opacity-0'
                          }`}
                        >
                          <code className="block bg-gray-800 p-2 rounded mt-2 w-fit">
                            ssh {user.username}@{instance.hostname}
                          </code>
                          <button
                            onClick={() =>
                              handleCopy(
                                `ssh ${user.username}@${instance.hostname}`
                              )
                            }
                          >
                            <ContentCopyIcon className="text-white hover:text-gray-400 cursor-pointer" />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="grid justify-center items-center py-5 md:pt-52 sm:pt-32 pt-32">
                  <div className="grid justify-center">
                    <PlaylistRemoveIcon
                      sx={{
                        scale: '2.5',
                      }}
                    />
                  </div>
                  <p className="lg:text-[35px] md:text-[28px] sm:text-[22px] text-[18px] font-['Poppins'] p-5">
                    No Instances Found
                  </p>
                </div>
              )}
            </div>
          }
        />
        <Route
          path="/create-instance"
          element={
            <ProtectedRoute allowedRoles={['ADMIN']}>
              <CreateInstance
                refetchInstances={refetchInstances}
                setRefetchInstances={setRefetchInstances}
              />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default Instances;
