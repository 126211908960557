import { useEffect } from 'react';
import Logo from '../../assets/Logo.png';
import { useNavigate } from 'react-router-dom';

function Landing() {
  const navigate = useNavigate();
  const token = localStorage.getItem('authToken');

  useEffect(() => {
    navigate('/signup');
    if (token) {
      navigate('/homepage/dashboard');
    }
  });

  return (
    <div className="bg-black text-white clash-display h-screen">
      <div className="flex justify-between p-5">
        <div className="flex gap-5 items-center">
          <div>Public Cloud</div>
          <div>Features</div>
          <div>Pricing</div>
          <div>FAQs</div>
        </div>
        <div>
          <img src={Logo} alt="logo" />
        </div>
        <div className="flex gap-3">
          <button
            className="bg-black border px-[24px] rounded-[12px] py-[8px] border-[#323232]"
            onClick={() => navigate('/signup')}
          >
            Sign Up
          </button>
          <button
            className="bg-[#8B5CF6] px-[24px] py-[8px] rounded-[12px] border border-[#AE8DF9]"
            onClick={() => navigate('/signup')}
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
}

export default Landing;
