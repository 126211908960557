import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import axios from 'axios';
import Logo from '../../assets/Logo.png';
import Background from '../../assets/Background.png';
import awsLogo from '../../assets/aws.png';
import coinbase from '../../assets/coinbase.png';
import coingecko from '../../assets/coingecko.png';
import gc from '../../assets/gc.png';
import ibm from '../../assets/ibm.png';
import nvidia from '../../assets/nvidia.png';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';
import { toast } from 'sonner';
import UserContextType from '../../interfaces/auth-context';
import { UserContext } from '../../contexts/UserContext';

type Inputs = {
  identifier: string;
  password: string;
  resetEmail: string;
};

function Login() {
  const [resetPassword, setResetPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const { setUser } = useContext<UserContextType>(UserContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSignInSubmit: SubmitHandler<Inputs> = async (data) => {
    const { identifier, password } = data;
    axios
      .post(process.env.REACT_APP_API_URL + '/users/signin', {
        identifier,
        password,
      })
      .then((res) => {
        const { user, token } = res.data.data;
        localStorage.setItem('authToken', token);
        setUser(user);
        toast.success(res.data.message);
        navigate('/homepage/dashboard');
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const requestPasswordChange = async (email: string) => {
    axios
      .post(process.env.REACT_APP_API_URL + '/users/request-password-reset', {
        email,
      })
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const onResetPasswordSubmit: SubmitHandler<Inputs> = async (data) => {
    requestPasswordChange(data.resetEmail);
  };

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      navigate('/homepage/dashboard');
    }
  }, []);

  return (
    <div className="h-fit">
      <div className="w-full fixed h-screen bg-black aspect-video">
        <img src={Background} alt="background" />
      </div>
      <div className="w-full h-fit absolute">
        <div className="flex justify-between sm:p-10 p-5">
          <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={() => navigate('/')}
          >
            <img src={Logo} alt="logo" />
            <h1 className="text-white md:text-[26px] text-[12px] font-[400]">
              <strong>Deploy</strong>.paal
            </h1>
          </div>
          <div
            className="flex items-center gap-3 justify-center cursor-pointer"
            onClick={() => navigate('/signup')}
          >
            <h1 className="text-[#8B5CF6] md:text-[20px] text-[16px]">
              Sign up
            </h1>
            <Arrow />
          </div>
        </div>
        <div className="md:flex gap-3 w-full md:text-start text-center h-fit">
          <div className="w-full items-center flex justify-center md:pt-[10.2rem]">
            <div className="w-3/4 ">
              <div className="text-[#838383] xl:text-[45px] md:text-[30px] text-[22px] font-[400] lg:mx-24 md:mx-10 mx-5 ">
                Train and infer AI models faster{' '}
                <strong className="text-white">with our powerful GPU</strong>
              </div>
              <div className="grid grid-cols-6 md:grid-cols-3 gap-3 py-5 xl:px-24 lg:px-24 md:px-10 px-5">
                <img src={gc} alt="logo" />
                <img src={ibm} alt="logo" />
                <img src={coingecko} alt="logo" />
                <img src={nvidia} alt="logo" />
                <img src={awsLogo} alt="logo" />
                <img src={coinbase} alt="logo" />
              </div>
            </div>
          </div>
          <div className="text-white w-full flex h-fit sm:items-center md:justify-start justify-center">
            {resetPassword ? (
              <form
                className="w-3/4 md:py-32 md:m-10 p-5"
                onSubmit={handleSubmit(onResetPasswordSubmit)}
              >
                <h1 className="xl:text-[40px] md:text-[30px] sm:text-[24px] font-[400]">
                  Reset your Password
                </h1>
                <input
                  type="text"
                  placeholder="Email"
                  className="w-full bg-none border-b-2 outline-none pt-14 text-[#838383] bg-[#0000] sm:text-[16px] text-[14px]"
                  {...register('resetEmail', {
                    required: true,
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                      message: 'Please enter a valid email address',
                    },
                  })}
                />
                {errors.resetEmail && (
                  <p className="text-red-500">{errors.resetEmail?.message}</p>
                )}
                <button
                  className="w-full bg-[#8B5CF6] text-black p-3 mt-10 rounded-xl font-[500]"
                  type="submit"
                >
                  Send reset email
                </button>
              </form>
            ) : (
              <form
                className="w-3/4 md:p-10 md:m-10 p-5"
                onSubmit={handleSubmit(onSignInSubmit)}
              >
                <h1 className="xl:text-[40px] md:text-[30px] sm:text-[24px] font-[400]">
                  Sign in to your account
                </h1>
                <input
                  type="text"
                  placeholder="Email"
                  className="w-full border-b-2 outline-none pt-14 text-[#838383] bg-black sm:text-[16px] text-[14px]"
                  {...register('identifier', {
                    required: true,
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                      message: 'Please enter a valid email address',
                    },
                  })}
                />
                {errors.identifier && (
                  <p className="text-red-500">{errors.identifier?.message}</p>
                )}
                <input
                  type="password"
                  placeholder="Password"
                  className="w-full bg-none border-b-2 outline-none pt-14 text-[#838383] bg-[#0000] sm:text-[16px] text-[14px]"
                  {...register('password', { required: true })}
                />
                <button
                  className="w-full bg-[#8B5CF6] text-black p-3 mt-10 rounded-xl font-[500]"
                  type="submit"
                >
                  Sign in
                </button>
                <p className="text-[#838383] mt-10 md:text-[20px] text-[10px]">
                  Forgot your password?{' '}
                  <span
                    className="text-[#8B5CF6] cursor-pointer hover:underline"
                    onClick={() => setResetPassword(true)}
                  >
                    Reset your password
                  </span>
                </p>
                <p className="text-[#838383] md:text-[20px] text-[10px]">
                  Don't have an account?{' '}
                  <span
                    className="text-[#8B5CF6] cursor-pointer hover:underline"
                    onClick={() => navigate('/signup')}
                  >
                    Sign up
                  </span>
                </p>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
