import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Drawer, List, Toolbar } from '@mui/material';
import { ListItem, ListItemButton } from '@mui/material';
import Logo from '../../assets/Logo.png';
import { ReactComponent as ReverseArrow } from '../../assets/reverse-arrow.svg';
import { ReactComponent as ActiveDashboardIcon } from '../../assets/activeDashboardIcon.svg';
import { ReactComponent as InactiveDashboardIcon } from '../../assets/inactiveDashboardIcon.svg';
import { ReactComponent as ActiveInstanceIcon } from '../../assets/activeInstanceIcon.svg';
import { ReactComponent as InactiveInstanceIcon } from '../../assets/inactiveInstanceIcon.svg';
import { ReactComponent as ActiveClusterIcon } from '../../assets/activeClusterIcon.svg';
import { ReactComponent as InactiveClusterIcon } from '../../assets/inactiveClusterIcon.svg';
import { ReactComponent as ActiveStorageIcon } from '../../assets/activeStorageIcon.svg';
import { ReactComponent as InactiveStorageIcon } from '../../assets/inactiveStorageIcon.svg';
import { ReactComponent as ActiveFirewallIcon } from '../../assets/activeFirewallIcon.svg';
import { ReactComponent as InactiveFirewallIcon } from '../../assets/inactiveFirewallIcon.svg';
import { ReactComponent as ActivePricingIcon } from '../../assets/activePricingIcon.svg';
import { ReactComponent as InactivePricingIcon } from '../../assets/inactivePricingIcon.svg';
import { ReactComponent as ActiveFAQIcon } from '../../assets/activeFaqIcon.svg';
import { ReactComponent as InactiveFAQIcon } from '../../assets/inactiveFaqIcon.svg';
import { ReactComponent as ActiveSSHIcon } from '../../assets/activeSSHIcon.svg';
import { ReactComponent as InactiveSSHIcon } from '../../assets/inactiveSSHIcon.svg';
import { ReactComponent as ActiveProfileIcon } from '../../assets/activeProfileIcon.svg';
import { ReactComponent as InactiveProfileIcon } from '../../assets/inactiveProfileIcon.svg';
import { ReactComponent as ActiveSettingsIcon } from '../../assets/activeSettingsIcon.svg';
import { ReactComponent as InactiveSettingsIcon } from '../../assets/inactiveSettingsIcon.svg';
import { ReactComponent as ActiveTeamIcon } from '../../assets/activeTeamIcon.svg';
import { ReactComponent as InactiveTeamIcon } from '../../assets/inactiveTeamIcon.svg';

interface Props {
  window?: () => Window;
  handleDrawerToggle: () => void;
  handleDrawerClose: () => void;
  handleDrawerTransitionEnd: () => void;
  mobileOpen: boolean;
}

function SideNav({
  window,
  handleDrawerToggle,
  handleDrawerClose,
  handleDrawerTransitionEnd,
  mobileOpen,
}: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const drawerWidth = 240;
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <div className="bg-[#0A0010] text-white h-screen">
      <Toolbar
        children={
          <div>
            <div className="custom-small:hidden" onClick={handleDrawerClose}>
              <ReverseArrow />
            </div>
            <div
              className="items-center gap-3 custom-small:flex hidden cursor-pointer text-button"
              onClick={() => navigate('/')}
            >
              <img src={Logo} alt="logo" className="w-[40px] h-[40px]" />
              <h1 className="text-white md:text-[20px] text-[12px] font-[400]">
                <strong>Deploy</strong>.paal
              </h1>
            </div>
          </div>
        }
      />
      <div
        className="justify-between flex flex-col h-full"
        style={{
          height: `calc(100% - 65px)`,
        }}
      >
        <List className="h-min font-['Poppins']">
          <div>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/homepage/dashboard`);
                  mobileOpen && handleDrawerClose();
                }}
              >
                <div className="flex gap-2 items-center px-5">
                  {location.pathname === '/homepage/dashboard' ? (
                    <ActiveDashboardIcon />
                  ) : (
                    <InactiveDashboardIcon />
                  )}
                  <span
                    className={`${location.pathname === '/homepage/dashboard' ? 'text-[#8B5CF6]' : 'text-[#3D3D3D] '} font-['Poppins']`}
                  >
                    Dashboard
                  </span>
                </div>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/homepage/templates`);
                  mobileOpen && handleDrawerClose();
                }}
              >
                <div className="flex gap-2 items-center px-5">
                  {location.pathname.includes('/homepage/templates') ? (
                    <ActiveDashboardIcon />
                  ) : (
                    <InactiveDashboardIcon />
                  )}
                  <span
                    className={`${location.pathname.includes('/homepage/templates') ? 'text-[#8B5CF6]' : 'text-[#3D3D3D] '} font-['Poppins']`}
                  >
                    Templates
                  </span>
                </div>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/homepage/instances`);
                  mobileOpen && handleDrawerClose();
                }}
              >
                <div className="flex gap-2 items-center px-5">
                  {location.pathname.includes('/homepage/instances') ? (
                    <ActiveInstanceIcon />
                  ) : (
                    <InactiveInstanceIcon />
                  )}
                  <span
                    className={`${location.pathname.includes('/homepage/instances') ? 'text-[#8B5CF6]' : 'text-[#3D3D3D] '} font-['Poppins']`}
                  >
                    Instances
                  </span>
                </div>
              </ListItemButton>
            </ListItem>
            {/* <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/homepage/clusters`);
                  mobileOpen && handleDrawerClose();
                }}
              >
                <div className="flex gap-2 items-center px-5">
                  {location.pathname === '/homepage/clusters' ? (
                    <ActiveClusterIcon />
                  ) : (
                    <InactiveClusterIcon />
                  )}
                  <span
                    className={`${location.pathname === '/homepage/clusters' ? 'text-[#8B5CF6]' : 'text-[#3D3D3D] '} font-['Poppins']`}
                  >
                    Clusters
                  </span>
                </div>
              </ListItemButton>
            </ListItem> */}
            {/* <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/homepage/storage`);
                  mobileOpen && handleDrawerClose();
                }}
              >
                <div className="flex gap-2 items-center px-5">
                  {location.pathname === '/homepage/storage' ? (
                    <ActiveStorageIcon />
                  ) : (
                    <InactiveStorageIcon />
                  )}
                  <span
                    className={`${location.pathname === '/homepage/storage' ? 'text-[#8B5CF6]' : 'text-[#3D3D3D] '} font-['Poppins']`}
                  >
                    Storage
                  </span>
                </div>
              </ListItemButton>
            </ListItem> */}
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/homepage/firewall`);
                  mobileOpen && handleDrawerClose();
                }}
              >
                <div className="flex gap-2 items-center px-5">
                  {location.pathname === '/homepage/firewall' ? (
                    <ActiveFirewallIcon />
                  ) : (
                    <InactiveFirewallIcon />
                  )}
                  <span
                    className={`${location.pathname === '/homepage/firewall' ? 'text-[#8B5CF6]' : 'text-[#3D3D3D] '} font-['Poppins']`}
                  >
                    Firewall
                  </span>
                </div>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/homepage/ssh-keys`);
                  mobileOpen && handleDrawerClose();
                }}
              >
                <div className="flex gap-2 items-center px-5">
                  {location.pathname === '/homepage/ssh-keys' ? (
                    <ActiveSSHIcon />
                  ) : (
                    <InactiveSSHIcon />
                  )}
                  <span
                    className={`${location.pathname === '/homepage/ssh-keys' ? 'text-[#8B5CF6]' : 'text-[#3D3D3D] '} font-['Poppins']`}
                  >
                    SSH keys
                  </span>
                </div>
              </ListItemButton>
            </ListItem>
            {/* <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/homepage/faqs`);
                  mobileOpen && handleDrawerClose();
                }}
              >
                <div className="flex gap-2 items-center px-5">
                  {location.pathname === '/homepage/faqs' ? (
                    <ActiveFAQIcon />
                  ) : (
                    <InactiveFAQIcon />
                  )}
                  <span
                    className={`${location.pathname === '/homepage/faqs' ? 'text-[#8B5CF6]' : 'text-[#3D3D3D] '} font-['Poppins']`}
                  >
                    FAQs
                  </span>
                </div>
              </ListItemButton>
            </ListItem> */}
            {/* <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/homepage/pricing`);
                  mobileOpen && handleDrawerClose();
                }}
              >
                <div className="flex gap-2 items-center px-5">
                  {location.pathname === '/homepage/pricing' ? (
                    <ActivePricingIcon />
                  ) : (
                    <InactivePricingIcon />
                  )}
                  <span
                    className={`${location.pathname === '/homepage/pricing' ? 'text-[#8B5CF6]' : 'text-[#3D3D3D] '} font-['Poppins']`}
                  >
                    Pricing
                  </span>
                </div>
              </ListItemButton>
            </ListItem> */}
          </div>
        </List>
        <List className="h-min font-['Poppins']">
          <div>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/homepage/profile`);
                  mobileOpen && handleDrawerClose();
                }}
              >
                <div className="flex gap-2 items-center px-5">
                  {location.pathname === '/homepage/profile' ? (
                    <ActiveProfileIcon />
                  ) : (
                    <InactiveProfileIcon />
                  )}
                  <span
                    className={`${location.pathname === '/homepage/profile' ? 'text-[#8B5CF6]' : 'text-[#3D3D3D] '} font-['Poppins']`}
                  >
                    Profile
                  </span>
                </div>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/homepage/team`);
                  mobileOpen && handleDrawerClose();
                }}
              >
                <div className="flex gap-2 items-center px-5">
                  {location.pathname === '/homepage/team' ? (
                    <ActiveTeamIcon />
                  ) : (
                    <InactiveTeamIcon />
                  )}
                  <span
                    className={`${location.pathname === '/homepage/team' ? 'text-[#8B5CF6]' : 'text-[#3D3D3D] '} font-['Poppins']`}
                  >
                    Team
                  </span>
                </div>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/homepage/settings`);
                  mobileOpen && handleDrawerClose();
                }}
              >
                <div className="flex gap-2 items-center px-5">
                  {location.pathname === '/homepage/settings' ? (
                    <ActiveSettingsIcon />
                  ) : (
                    <InactiveSettingsIcon />
                  )}
                  <span
                    className={`${location.pathname === '/homepage/settings' ? 'text-[#8B5CF6]' : 'text-[#3D3D3D] '} font-['Poppins']`}
                  >
                    Settings
                  </span>
                </div>
              </ListItemButton>
            </ListItem>
          </div>
        </List>
      </div>
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            margin: '50px 0px',
            height: '95vh',
            border: '1px solid #8B5CF6',
            borderLeft: 'none',
            borderTopRightRadius: '20px',
            backgroundColor: '#0A0010',
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            borderRightColor: '#212121',
            height: '100vh',
            backgroundColor: '#0A0010',
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

export default SideNav;
