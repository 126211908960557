import { useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Profile from './profile';
import Header from './header';
import SideNav from './sideNav';
import Dashboard from './dashboard';
import Team from './team';
import Settings from './settings';
import UserContextType from '../../interfaces/auth-context';
import { UserContext } from '../../contexts/UserContext';
import ProtectedRoute from '../ProtectedRoute';
import SSHKeys from './sshKeys';
import Instances from './instances';
import Firewall from './firewall';
import Templates from './templates';
import SetupTemplates from './setupTemplates';

const drawerWidth = 240;

const Homepage = () => {
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [isClosing, setIsClosing] = useState<boolean>(false);
  const navigate = useNavigate();
  const { loading, setLoading } = useContext<UserContextType>(UserContext);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem('authToken');
    if (!token) {
      navigate('/login');
    }
    setLoading(false);
  }, []);

  return (
    <Box
      sx={{
        display: `${loading ? 'none' : 'flex'}`,
        backgroundColor: '#0A0010',
      }}
      className="clash-display"
    >
      <CssBaseline />
      <Header handleDrawerToggle={handleDrawerToggle} />
      <SideNav
        handleDrawerClose={handleDrawerClose}
        handleDrawerToggle={handleDrawerToggle}
        handleDrawerTransitionEnd={handleDrawerTransitionEnd}
        mobileOpen={mobileOpen}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          minHeight: '100vh',
          paddingTop: ['65px', '10px'],
        }}
        className="text-white bg-black custom-small:pt-0 pt-[60px]"
      >
        <Toolbar />
        <Routes>
          <Route path="/profile" element={<Profile />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/templates" element={<Templates />} />
          <Route path="/templates/setup" element={<SetupTemplates />} />
          <Route
            path="/team"
            element={
              <ProtectedRoute allowedRoles={['ADMIN']}>
                <Team />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute allowedRoles={['ADMIN']}>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ssh-keys"
            element={
              <ProtectedRoute allowedRoles={['ADMIN', 'VIEWER', 'DEVELOPER']}>
                <SSHKeys />
              </ProtectedRoute>
            }
          />
          <Route
            path="/instances/*"
            element={
              <ProtectedRoute allowedRoles={['ADMIN', 'VIEWER', 'DEVELOPER']}>
                <Instances />
              </ProtectedRoute>
            }
          />
          <Route
            path="/firewall"
            element={
              <ProtectedRoute allowedRoles={['ADMIN', 'VIEWER', 'DEVELOPER']}>
                <Firewall />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Box>
    </Box>
  );
};

export default Homepage;
