import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Background from '../../assets/Background.png';
import Logo from '../../assets/Logo.png';
import awsLogo from '../../assets/aws.png';
import coinbase from '../../assets/coinbase.png';
import coingecko from '../../assets/coingecko.png';
import gc from '../../assets/gc.png';
import ibm from '../../assets/ibm.png';
import nvidia from '../../assets/nvidia.png';
import { SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import { toast } from 'sonner';

type Inputs = {
  password: string;
  reEnterPassword: string;
  username: string;
  phoneNumber: string;
};

function Invitation() {
  const [token, setToken] = useState<string | null>(null);
  const authToken = localStorage.getItem('authToken');

  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Inputs>();
  const newPassword = watch('password');

  const acceptInvitation: SubmitHandler<Inputs> = async (data) => {
    const { reEnterPassword, phoneNumber, ...rest } = data;
    const payload = {
      ...rest,
      ...(phoneNumber && { phoneNumber }),
    };
    axios
      .post(
        process.env.REACT_APP_API_URL + '/users/accept-invitation',
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        navigate('/login');
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    if (authToken) {
      navigate('/homepage/dashboard');
    } else {
      const searchParams = new URLSearchParams(location.search);
      const tokenFromUrl = searchParams.get('token');
      setToken(tokenFromUrl);
    }
  }, [location.search]);

  return (
    <div>
      <div className="w-full h-screen fixed bg-black aspect-video	">
        <img src={Background} alt="background" />
      </div>
      <div className="w-full absolute h-screen">
        <div className="flex justify-between sm:p-10 p-5">
          <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={() => navigate('/')}
          >
            <img src={Logo} alt="logo" />
            <h1 className="text-white md:text-[26px] text-[12px] font-[400]">
              <strong>Deploy</strong>.paal
            </h1>
          </div>
        </div>
        <div className="md:flex gap-3 w-full md:text-start text-center">
          <div className="w-full items-center flex justify-center">
            <div className="w-3/4 ">
              <div className="text-[#838383] xl:text-[45px] md:text-[30px] text-[22px] font-[400] lg:mx-24 md:mx-10 mx-5 ">
                Train and infer AI models faster{' '}
                <strong className="text-white">with our powerful GPU</strong>
              </div>
              <div className="grid grid-cols-6 md:grid-cols-3 gap-3 py-5 xl:px-24 lg:px-24 md:px-10 px-5">
                <img src={gc} alt="logo" />
                <img src={ibm} alt="logo" />
                <img src={coingecko} alt="logo" />
                <img src={nvidia} alt="logo" />
                <img src={awsLogo} alt="logo" />
                <img src={coinbase} alt="logo" />
              </div>
            </div>
          </div>
          <div className="text-white w-full flex h-fit sm:items-center md:justify-start justify-center">
            <form
              className="w-3/4 md:p-0 p-5"
              onSubmit={handleSubmit(acceptInvitation)}
            >
              <h1 className="xl:text-[40px] md:text-[30px] sm:text-[24px] font-[400] pb-10">
                Complete Your Registration
              </h1>
              <input
                type="text"
                placeholder="Enter Username"
                className="w-full bg-none border-b-2 outline-none pt-5 text-[#838383] bg-[#0000] sm:text-[16px] text-[14px]"
                {...register('username', {
                  required: true,
                })}
              />
              {errors.username && (
                <p className="text-red-500 text-sm mt-1">
                  Username is required
                </p>
              )}
              <input
                type="number"
                placeholder="Enter Phone Number"
                className="w-full bg-none border-b-2 outline-none pt-14 text-[#838383] bg-[#0000] sm:text-[16px] text-[14px]"
                {...register('phoneNumber', {
                  required: false,
                })}
              />
              <input
                type="password"
                placeholder="Enter New Password"
                className="w-full bg-none border-b-2 outline-none pt-14 text-[#838383] bg-[#0000] sm:text-[16px] text-[14px]"
                {...register('password', {
                  required: true,
                  minLength: {
                    value: 8,
                    message: 'Password must be at least 8 characters long',
                  },
                })}
              />
              {errors.password && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.password.message}
                </p>
              )}
              <input
                type="password"
                placeholder="Re-Enter Password"
                className="w-full bg-none border-b-2 outline-none pt-14 text-[#838383] bg-[#0000] sm:text-[16px] text-[14px]"
                {...register('reEnterPassword', {
                  required: true,
                  validate: (value) =>
                    value === newPassword || 'Passwords do not match',
                })}
              />
              {errors.reEnterPassword && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.reEnterPassword.message}
                </p>
              )}
              <button
                className="w-full bg-[#8B5CF6] text-black p-3 mt-5 rounded-xl font-[500]"
                type="submit"
              >
                Set Up Account
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invitation;
