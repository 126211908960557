import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BittensorBg from '../../assets/bittensor-bg.png';
import BittensorLogo from '../../assets/bittensor-tao-logo.png';

const SetupTemplates = () => {
  return (
    <div className="clash-display">
      <div className="flex justify-between gap-1">
        <div className="clash-display text-[20px]">How to Setup</div>
        <div className="lg:flex justify-end gap-2 hidden overflow-hidden">
          <button className="min-w-fit min-h-fit clash-display md:h-[33px] lg:text-[14px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] font-semibold border rounded-[8px] lg:px-5 md:px-4 sm:px-3 px-1 border-[#4E00FF] bg-[#8B5CF6]">
            Bittensor Mining
          </button>
          <button className="min-w-fit clash-display min-h-fit max-h-[33px] lg:text-[14px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-5 md:px-4 sm:px-3 px-1 border-[#cacacc] bg-black bg-gradient-radial from-[#00000059] to-[#FFFFFF1A]">
            Ethereum Validator
          </button>
          <button className="min-w-fit clash-display min-h-fit max-h-[33px] lg:text-[14px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-5 md:px-4 sm:px-3 px-1 border-[#cacacc] bg-black bg-gradient-radial from-[#00000059] to-[#FFFFFF1A]">
            Fine Tuning Model
          </button>
          <button className="min-w-fit clash-display min-h-fit max-h-[33px] lg:text-[14px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-5 md:px-4 sm:px-3 px-1 border-[#cacacc] bg-black bg-gradient-radial from-[#00000059] to-[#FFFFFF1A]">
            Solana Validator
          </button>
          <button className="min-w-fit clash-display min-h-fit max-h-[33px] lg:text-[14px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-5 md:px-4 sm:px-3 px-1 border-[#cacacc] bg-black bg-gradient-radial from-[#00000059] to-[#FFFFFF1A]">
            AI Inference
          </button>
          <button className="min-w-fit clash-display min-h-fit max-h-[33px] lg:text-[14px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-5 md:px-4 sm:px-3 px-1 border-[#cacacc] bg-black bg-gradient-radial from-[#00000059] to-[#FFFFFF1A]">
            LLM Training
          </button>
          <div className="flex items-center">
            <ArrowForwardIosIcon />
          </div>
        </div>
      </div>
      <div className="lg:flex grid border border-[#fff] border-opacity-35 rounded-[24px] my-10 bg-gradient-radial from-[#00000059] to-[#FFFFFF1A] bg-black">
        <div className="lg:max-w-[340px] border lg:border-r-[#2B1953] border-b-[#2B1953] border-transparent bg-black lg:rounded-l-[24px] rounded-l-0 lg:rounded-tr-none rounded-t-[24px]">
          <div className="relative">
            <div className="absolute h-full w-full flex items-center justify-center z-10">
              <div className="lg:max-h-none max-h-[300px] flex flex-col items-center">
                <img
                  src={BittensorLogo}
                  alt="Bittensor Mining"
                  className=" w-[33px] h-[37px]"
                />
                <div className="text-center text-[20px] font-semibold clash-display">
                  Bittensor Mining
                </div>
              </div>
            </div>
            <img src={BittensorBg} alt="asd" className="w-full relative" />
          </div>
        </div>
        <div className="bg-black flex justify-center items-center flex-1">
          <div className="text-center">
            <div className="grid justify-center bg-black py-2 border-opacity-20">
              <div className="text-[10px]">g4dn.16xlarge</div>
              <div className="text-[#555555] text-[11px]">Recommended GPU</div>
            </div>
            <div className="grid justify-center bg-black py-2">
              <div className="text-[10px]">72 Hours</div>
              <div className="text-[#555555] text-[11px]">
                Recommended Hours
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center flex-1 bg-black lg:justify-start justify-center">
          <div className="grid text-start">
            <div className="xl:text-[48px] lg:text-[40px] md:text-[26px] sm:text-[20px] text-[20px] lg:text-start text-center">
              <div className="font-thin leading-8">How To</div>
              <div className="font-semibold">Setup Guide.</div>
            </div>
          </div>
        </div>
        <div className="justify-center items-center grid flex-1 bg-black lg:rounded-r-[24px] rounded-b-[24px] lg:py-0 py-3">
          <div>
            <button className="clash-display h-fit md:min-h-[33px] lg:text-[14px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] font-semibold border rounded-[8px] lg:px-5 md:px-4 sm:px-3 px-1 border-[#4E00FF] bg-[#8B5CF6]">
              Bittensor Mining
            </button>
          </div>
        </div>
      </div>
      <div className="lg:flex gap-3">
        <div className="flex-1 gap-4 grid h-fit">
          <div className="bg-black bg-gradient-radial from-[#00000059] to-[#FFFFFF1A] border border-[#fff] border-opacity-35 rounded-[10px] p-5">
            <div className="sm:flex justify-between">
              <div className="flex gap-5 items-center">
                <div className="text-[14px]">Setup SSH Key for Paal.host</div>
                <button className="clash-display md:h-[33px] text-[10px] font-['Poppins'] font-semibold border rounded-[8px] lg:px-5 md:px-4 sm:px-3 px-1 border-[#fff] border-opacity-35 bg-[#8B5CF6]">
                  SSH Key
                </button>
              </div>
              <div className="md:text-[21px] sm:text-[19px] text-[16px] text-[#959595] min-w-fit self-center">
                Step 1
              </div>
            </div>
            <div className="text-[12px] pt-2">Generate a Public Key</div>
            <div className="grid gap-1 font-['Poppins'] text-[10px] py-4">
              <div className="text-[#999999]">
                To generate an SSH key for securely accessing your GPU instance,
                follow these steps:
              </div>
              <div className="pt-2">
                1. Open your terminal (on Linux/macOS) or Git Bash (on Windows).
              </div>
              <div className="pb-2">
                2. Run the following command to generate an RSA key pair:
              </div>
              <span className="text-[#999999] pt-2">
                ssh-keygen -t rsa -b 4096 -C "
                <span className="text-[#C6ADFF]">your_email@example.com</span>"
              </span>
              <span className="text-[#999999] pb-2">
                Replace{' '}
                <span className="text-[#C6ADFF]">your_email@example.com</span>{' '}
                with your own email address.
              </span>
              <div>
                3. When prompted, press Enter to save the key in the default
                file location (~/.ssh/id_rsa).
              </div>
              <div>
                4. Enter a secure passphrase when prompted, or press Enter to
                skip.
              </div>
              <div>5. Your public key will be stored in ~/.ssh/id_rsa.pub.</div>
              <div className="text-[#999999] pt-2">
                To view and copy your public key, run:
              </div>
              <div className="text-[#C6ADFF] py-2">cat ~/.ssh/id_rsa.pub</div>
              <div>
                Once you have the public key, upload it to paal.host during the
                instance creation process.
              </div>
            </div>
          </div>
          <div className="bg-black bg-gradient-radial from-[#00000059] to-[#FFFFFF1A] border border-[#fff] border-opacity-35 rounded-[10px] p-5">
            <div className="flex justify-between gap-1">
              <div className="flex gap-5 items-center">
                <div className="text-[14px]">
                  Launch an Instance on Paal.host
                </div>
                <button className="clash-display md:h-[33px] text-[10px] font-['Poppins'] font-semibold border rounded-[8px] lg:px-5 md:px-4 sm:px-3 px-1 border-[#fff] border-opacity-35 bg-[#8B5CF6]">
                  Instances
                </button>
              </div>
              <div className="md:text-[21px] sm:text-[19px] text-[16px] text-[#959595] min-w-fit self-center">
                Step 2
              </div>
            </div>
            <div className="grid font-['Poppins'] text-[10px] py-4">
              <div>1. Navigate to Paal.host Instances Page.</div>
              <div>2. Choose a GPU rental that suits your mining needs.</div>
              <div>
                3. Attach your SSH public key when creating the instance for
                easy access.
              </div>
            </div>
          </div>
          <div className="bg-black bg-gradient-radial from-[#00000059] to-[#FFFFFF1A] border border-[#fff] border-opacity-35 rounded-[10px] p-5">
            <div className="flex justify-between gap-1">
              <div className="text-[14px]">Access Your GPU Server</div>
              <div className="md:text-[21px] sm:text-[19px] text-[16px] text-[#959595] min-w-fit self-center">
                Step 3
              </div>
            </div>
            <div className="grid gap-3 font-['Poppins'] text-[10px] py-4">
              <div className="text-[#999999]">
                Once the instance is launched, access it using the following SSH
                command:
              </div>
              <div>
                <span className="text-[#C6ADFF]">ssh username@</span>
                {'<your-instance-ip>'}
                <span className="text-[#C6ADFF]">.compute.amazonaws.com</span>
              </div>
              <div>
                Replace username with your actual username{' '}
                <span className="text-[#999999]">
                  (usually “<span className="text-[#C6ADFF]">ubuntu</span>”) and{' '}
                  {'<'}
                  <span className="text-[#C6ADFF]">your-instance-ip</span>
                  {'>'} with the{' '}
                </span>
                <span className="text-[#C6ADFF]">
                  public IP address of your server.
                </span>
              </div>
            </div>
          </div>
          <div className="bg-black bg-gradient-radial from-[#00000059] to-[#FFFFFF1A] border border-[#fff] border-opacity-35 rounded-[10px] p-5">
            <div className="flex justify-between gap-1">
              <div className="text-[14px]">
                Update the System and Install Dependencies
              </div>
              <div className="md:text-[21px] sm:text-[19px] text-[16px] text-[#959595] min-w-fit self-center">
                Step 4
              </div>
            </div>
            <div className="grid gap-1 font-['Poppins'] text-[10px] py-4">
              <div className="text-[#999999] pb-2">
                Before starting the mining setup, ensure that the system is up
                to date and the necessary dependencies are installed.
              </div>
              <div> 1. Update the system packages:</div>
              <span className="text-[#C6ADFF] pb-2">
                sudo apt-get update && sudo apt-get upgrade -y
              </span>
              <div className=" pt-2"> 2. Install essential dependencies:</div>
              <div className="text-[#C6ADFF]">
                sudo apt-get install -y git python3 python3-pip
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 gap-4 grid h-fit lg:pt-0 pt-4">
          <div className="bg-black bg-gradient-radial from-[#00000059] to-[#FFFFFF1A] border border-[#fff] border-opacity-35 rounded-[10px] p-5">
            <div className="flex justify-between gap-1">
              <div className="flex gap-5 items-center">
                <div className="text-[14px]">
                  Clone the Bittensor Repository
                </div>
                <button className="clash-display md:h-[33px] text-[10px] font-['Poppins'] font-semibold border rounded-[8px] lg:px-5 md:px-4 sm:px-3 px-1 border-[#fff] border-opacity-35 bg-[#8B5CF6]">
                  Bittensor Repository
                </button>
              </div>
              <div className="md:text-[21px] sm:text-[19px] text-[16px] text-[#959595] min-w-fit self-center">
                Step 5
              </div>
            </div>
            <div className="grid gap-1 font-['Poppins'] text-[10px] py-4">
              <div className="text-[#999999]">
                Now, you will need to clone the Bettensor repository to set up
                mining:
              </div>
              <div className="pt-2">1. Clone the Bettensor repository:</div>
              <span className="py-2">
                git clone{' '}
                <span className="text-[#C6ADFF]">
                  https://github.com/bettensor/bettensor.git
                </span>
              </span>
              <div className="pt-2">
                2. Navigate to the Bettensor directory:
              </div>
              <span className="text-[#C6ADFF] pt-2">cd bettensor</span>
            </div>
          </div>
          <div className="bg-black bg-gradient-radial from-[#00000059] to-[#FFFFFF1A] border border-[#fff] border-opacity-35 rounded-[10px] p-5">
            <div className="flex justify-between gap-1">
              <div className="text-[14px]">
                Set Up the Bettensor Mining Environment
              </div>
              <div className="md:text-[21px] sm:text-[19px] text-[16px] text-[#959595] min-w-fit self-center">
                Step 6
              </div>
            </div>
            <div className="grid gap-1 font-['Poppins'] text-[10px] py-4">
              <div className="text-[#999999] pb-2">
                Once inside the Bettensor directory, you will need to run the
                setup script to configure your environment for mining.
              </div>
              <div> 1. Make the setup script executable:</div>
              <span className="text-[#C6ADFF] pb-2">
                chmod +x scripts/setup.sh
              </span>
              <div className=" pt-2">
                {' '}
                2. Run the setup script (use the appropriate flags depending on
                your setup): <br />• For mainnet:
              </div>
              <div className="text-[#C6ADFF]">
                source ./scripts/setup.sh --subtensor.network main
              </div>
              <div className=" pt-2">• For testnet:</div>
              <div className="text-[#C6ADFF]">
                source ./scripts/setup.sh --lite-node --subtensor.network test
              </div>
            </div>
          </div>
          <div className="bg-black bg-gradient-radial from-[#00000059] to-[#FFFFFF1A] border border-[#fff] border-opacity-35 rounded-[10px] p-5">
            <div className="flex justify-between gap-1">
              <div className="text-[14px]">Start Bittensor Mining</div>
              <div className="md:text-[21px] sm:text-[19px] text-[16px] text-[#959595] min-w-fit self-center">
                Step 7
              </div>
            </div>
            <div className="grid gap-1 font-['Poppins'] text-[10px] py-4">
              <div className="text-[#999999] pb-2">
                Now, you can start mining with Bettensor.
              </div>
              <div> 1. Start the miner:</div>
              <span className="text-[#C6ADFF] pb-2">python3 miner.py</span>
              <div className=" pt-2">
                2. Monitor the miner logs to ensure everything is working
                properly:
              </div>
              <div className="text-[#C6ADFF]">tail -f logs/miner.log</div>
              <div className=" py-4">Troubleshooting Tips:</div>
              <div className="text-[#999999]">1. Ensure Ports Are Open:</div>
              <div className="text-[#999999]">
                Make sure that the necessary ports (30333, 9944, 9933) are open
                and properly configured in your firewall rules.
              </div>
              <div className="text-[#999999]"> 2. Check Disk Space:</div>
              <div className="text-[#999999]">
                Ensure that your instance has enough disk space for running the
                miner. Use df -h to check available storage.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupTemplates;
