import { useContext, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { Dialog, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { UserContext } from '../../contexts/UserContext';
import UserContextType from '../../interfaces/auth-context';

type Inputs = {
  teamName: string;
};

function Settings() {
  const [openChangeTeamNameModal, setOpenChangeTeamNameModal] =
    useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { user, getUser, setLoading } =
    useContext<UserContextType>(UserContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const changeTeamName = async (data: Inputs) => {
    axios
      .patch(process.env.REACT_APP_API_URL + '/teams/change-teamname', data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      })
      .then((res) => {
        setOpenChangeTeamNameModal(false);
        toast.success(res.data.message);
        getUser();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const deleteAccount = async () => {
    setLoading(true);
    axios
      .delete(process.env.REACT_APP_API_URL + '/users/delete-account', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        localStorage.removeItem('authToken');
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
    setOpenDeleteModal(false);
  };

  return (
    <div className="w-full">
      <div className="pb-5 pt-2 flex custom-small:justify-start justify-center text-[40px]">
        Team Setting
      </div>
      <div className="py-5">
        <div className="text-[32px]">Team Name</div>
        <div className="text-[#787878] font-['Poppins'] text-[20px]">
          {user.teamName}
        </div>
        <button
          className="bg-[#8B5CF6] rounded-[17px] h-[55px] max-w-[535px] w-full text-black font-[600] font-['Poppins'] text-[20px] my-3"
          onClick={() => setOpenChangeTeamNameModal(true)}
        >
          Edit team name
        </button>
      </div>
      <div className="py-5">
        <div className="text-[32px]">Close account</div>
        <button
          className="bg-[#8B5CF6] rounded-[17px] h-[55px] max-w-[535px] w-full text-black font-[600] font-['Poppins'] text-[20px] my-3"
          onClick={() => {
            setOpenDeleteModal(true);
          }}
        >
          Edit team space
        </button>
      </div>
      <Dialog
        open={openChangeTeamNameModal}
        onClose={() => setOpenChangeTeamNameModal(false)}
        aria-labelledby="responsive-dialog-title"
        sx={{
          '.css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
            borderRadius: '20px',
            backgroundColor: '#000',
            border: '2px solid #8B5CF6',
            width: '500px',
          },
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className="text-white text-center"
        >
          {'Change Team Name'}
        </DialogTitle>
        <div className="m-10">
          <form onSubmit={handleSubmit(changeTeamName)} className="grid w-full">
            <div className="flex justify-center">
              <input
                className="border w-full custom-input border-[#505050] rounded-[10px] h-[46px] text-[#B7B7B7] md:text-[14px] sm:text-[12px] text-[10px] px-[2px] cursor-pointer my-2 bg-black p-5"
                type="text"
                {...register('teamName', {
                  required: true,
                })}
                defaultValue={user.teamName}
              />
            </div>
            {errors.teamName && (
              <p className="text-red-500">Team name is required</p>
            )}
            <div className="flex justify-center gap-5 w-full">
              <button className="bg-[#8B5CF6] w-2/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px] font-bold">
                Update
              </button>
              <button
                className="bg-[#8B5CF6] text-white w-2/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px]"
                onClick={() => setOpenChangeTeamNameModal(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Dialog>
      <Dialog
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        aria-labelledby="responsive-dialog-title"
        sx={{
          '.css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
            borderRadius: '20px',
            backgroundColor: '#000',
            border: '2px solid #8B5CF6',
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title" className="text-white">
          {'Are you sure you want to delete this account?'}
        </DialogTitle>
        <div className="flex justify-center gap-5">
          <button
            className="bg-[#8B5CF6]  w-1/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px] text-red-700 font-bold"
            onClick={deleteAccount}
          >
            <DeleteIcon />
            Yes
          </button>
          <button
            className="bg-[#8B5CF6] text-white w-1/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px]"
            onClick={() => {
              setOpenDeleteModal(false);
            }}
          >
            No
          </button>
        </div>
      </Dialog>
    </div>
  );
}

export default Settings;
