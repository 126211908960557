import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import { CircularProgress, Skeleton } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ReactComponent as RunningIcon } from '../../assets/active-icon.svg';
import { ReactComponent as StoppedIcon } from '../../assets/stopped-icon.svg';
import { ReactComponent as PendingIcon } from '../../assets/pending-icon.svg';
import DashboardImage from '../../assets/dashboard-image.png';
import TotalInstanceBg from '../../assets/total-instance-count.jpg';
import RunningInstanceBg from '../../assets/running-instances-count.png';
import StoppedInstanceBg from '../../assets/stopped-instance-count.png';
import { UserContext } from '../../contexts/UserContext';
import type Instance from '../../interfaces/instances';
import './index.css';

const Dashboard = () => {
  const [userInstances, setUserInstances] = useState<Instance[] | null>(null);
  const [expandedInstances, setExpandedInstances] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalInstances, setTotalInstances] = useState<number>(0);
  const [stoppedInstances, setStoppedInstances] = useState<number>(0);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const toggleInstanceDetails = (id: string) => {
    if (expandedInstances.includes(id)) {
      setExpandedInstances(
        expandedInstances.filter((instanceId) => instanceId !== id)
      );
    } else {
      setExpandedInstances([...expandedInstances, id]);
    }
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const getUserInstances = async () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/instances`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('authToken')}`,
        },
      })
      .then((res) => {
        setTotalInstances(res.data.data.length);
        setStoppedInstances(
          res.data.data.filter(
            (instance: Instance) => instance.state === 'stopped'
          ).length
        );
        const runningInstances = res.data.data.filter(
          (instance: Instance) => instance.state === 'running'
        );
        setUserInstances(runningInstances);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getUserInstances();
  }, []);

  return (
    <div className="w-full h-fit">
      <div className="dashboard-box w-full flex justify-between p-2">
        <div className="grid gap-2 items-center clash-display md:pl-10 pl-3">
          <div className="boostAiText grid items-center sm:text-[40px] md:text-[30px] lg:text-[65px] xl:text-[70px] text-[23px] md:w-full w-[150px]">
            <span className="font-[400]">Boost your AI</span>
            <span className="text-[#838383] xl:text-[24px] lg:text-[22px] md:text-[18px] text-[12px]">
              with our powerful GPU infrastructure
            </span>
          </div>
          <div className="button">
            <button
              className="bg-[#8B5CF6] h-min md:w-[149px] w-[104px] text-white rounded-[8px] md:pt-[8px] md:pr-[12px] md:pb-[12px] md:pl-[12px] pt-[6px] pr-[20px] pb-[6px] pl-[20px] sm:text-[10px] md:text-[14px] text-[12px] text-nowrap flex justify-center items-center"
              onClick={() => navigate('/homepage/instances/create-instance')}
            >
              Available GPUs
            </button>
          </div>
        </div>
        <div className="custom-small:h-[253px] h-[158px] sm:px-10">
          <img className="h-full" alt="pic" src={DashboardImage} />
        </div>
      </div>
      <div className="instanceNumber text-white pt-10">
        <div className="cardContainer md:flex grid gap-3 md:justify-normal justify-center">
          {loading ? (
            <Skeleton
              variant="rectangular"
              width={257}
              height={257}
              style={{ backgroundColor: '#555454', borderRadius: '23px' }}
            />
          ) : (
            <div
              className="card grid grid-rows-7 border border-[#e5e7eb] border-opacity-[35%] rounded-[23px] h-[257px] w-[257px]"
              style={{
                backgroundImage:
                  'radial-gradient(83.26% 148.71% at 83.26% 142.62%, rgba(255, 255, 255, 0.00116801) 79.1%, rgba(255, 255, 255, 0.1) 100%)',
              }}
            >
              <div
                className="card-value grid row-span-5 place-items-center w-full h-full text-[62px] font-[500] bg-[#1B002C] rounded-t-[23px] aspect-[1/1]"
                style={{
                  backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), 
            radial-gradient(83.26% 148.71% at 83.26% 142.62%, rgba(255, 255, 255, 0.00116801) 79.1%, rgba(255, 255, 255, 0.1) 100%), 
            url(${TotalInstanceBg})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                {totalInstances}
              </div>
              <div className="card-title grid row-span-2 border-t border-[#e5e7eb] border-opacity-[10%] place-items-center w-full h-full text-[20px] font-[500]">
                Total Instances
              </div>
            </div>
          )}
          {loading ? (
            <Skeleton
              variant="rectangular"
              width={257}
              height={257}
              style={{ backgroundColor: '#555454', borderRadius: '23px' }}
            />
          ) : (
            <div
              className="card grid grid-rows-7 border border-[#e5e7eb] border-opacity-[35%] rounded-[23px] h-[257px] w-[257px]"
              style={{
                backgroundImage:
                  'radial-gradient(83.26% 148.71% at 83.26% 142.62%, rgba(255, 255, 255, 0.00116801) 79.1%, rgba(255, 255, 255, 0.1) 100%)',
              }}
            >
              <div
                className="card-value grid row-span-5 place-items-center w-full h-full text-[62px] font-[500] bg-[#374b32] rounded-t-[23px] aspect-[1/1]"
                style={{
                  backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), 
            radial-gradient(83.26% 148.71% at 83.26% 142.62%, rgba(255, 255, 255, 0.00116801) 79.1%, rgba(255, 255, 255, 0.1) 100%), 
            url(${RunningInstanceBg})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                {userInstances ? userInstances.length : 0}
              </div>
              <div className="card-title grid row-span-2 border-t border-[#e5e7eb] border-opacity-[10%] place-items-center w-full h-full text-[20px] font-[500]">
                Running Instances
              </div>
            </div>
          )}
          {loading ? (
            <Skeleton
              variant="rectangular"
              width={257}
              height={257}
              style={{ backgroundColor: '#555454', borderRadius: '23px' }}
            />
          ) : (
            <div
              className="card grid grid-rows-7 border border-[#e5e7eb] border-opacity-[35%] rounded-[23px] h-[257px] w-[257px]"
              style={{
                backgroundImage:
                  'radial-gradient(83.26% 148.71% at 83.26% 142.62%, rgba(255, 255, 255, 0.00116801) 79.1%, rgba(255, 255, 255, 0.1) 100%)',
              }}
            >
              <div
                className="card-value grid row-span-5 place-items-center w-full h-full text-[62px] font-[500] bg-[#5e341b] rounded-t-[23px] aspect-[1/1]"
                style={{
                  backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), 
            radial-gradient(83.26% 148.71% at 83.26% 142.62%, rgba(255, 255, 255, 0.00116801) 79.1%, rgba(255, 255, 255, 0.1) 100%), 
            url(${StoppedInstanceBg})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                {stoppedInstances}
              </div>
              <div className="card-title grid row-span-2 border-t border-[#e5e7eb] border-opacity-[10%] place-items-center w-full h-full text-[20px] font-[500]">
                Stopped Instances
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="addMoreInstances flex justify-between items-center pt-10 px-2">
        <div className="text font-[400] text-[16px]">Your Instances</div>
        <div className="button">
          {user.role === 'ADMIN' && (
            <button
              className="addMore text-[14px] border border-[#e5e7eb] border-opacity-30 rounded-[12px] pt-[8px] pr-[24px] pb-[8px] pl-[24px]"
              onClick={() => navigate('/homepage/instances/create-instance')}
            >
              Add more
            </button>
          )}
        </div>
      </div>
      {loading ? (
        <div className="flex w-full justify-center py-20">
          <CircularProgress color="secondary" />
        </div>
      ) : userInstances !== null && userInstances.length > 0 ? (
        userInstances.map((instance) => {
          const date = new Date(instance.expirationTime);

          return (
            <div className="py-5" key={instance.id}>
              <div className="instance-box border border-[#ffffff99]">
                <div className="header font-['Poppins'] md:text-[20px] sm:text-[16px] text-[12px]">
                  <div>{instance.name}</div>
                  <div className="gap-2 flex items-center">
                    {instance.state === 'running' && (
                      <>
                        <RunningIcon />
                        <div className="capitalize">{instance.state}</div>
                      </>
                    )}
                  </div>
                </div>
                <div className="lg:flex grid gap-3 justify-between py-5 items-center custom-small:px-2">
                  <div className="grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-3 gap-2 font-['Poppins'] custom-small:text-[12px] text-[8px] font-[600] text-[#ADADAD]">
                    <div className="grid">
                      <div>VRAM / GPU</div>
                      <div>{instance.gpuDetails?.gpu} GB</div>
                    </div>
                    <div className="grid">
                      <div>vCPUs</div>
                      <div>{instance.gpuDetails?.vCPUs}</div>
                    </div>
                    <div className="grid">
                      <div>RAM</div>
                      <div>{instance.gpuDetails?.memoryGiB} GiB</div>
                    </div>
                    <div className="grid">
                      <div>STORAGE</div>
                      <div>{instance.gpuDetails?.instanceStorageGB}</div>
                    </div>
                    <div className="grid">
                      <div>PRICE</div>
                      {instance.gpuDetails?.price && (
                        <div>
                          {parseFloat(
                            instance?.gpuDetails?.price.toString()
                          ).toFixed(2)}{' '}
                          Cr / hr
                        </div>
                      )}
                    </div>
                    <div className="grid">
                      <div>Expiration Time</div>
                      <div>{date.toLocaleString('en-US')}</div>
                    </div>
                  </div>
                </div>
                <div
                  className={`flex justify-center cursor-pointer ${instance.state !== 'running' && 'hidden'}`}
                >
                  <ArrowDownwardIcon
                    onClick={() => toggleInstanceDetails(instance.id)}
                    className={`transition-transform duration-300 ${
                      expandedInstances.includes(instance.id)
                        ? 'rotate-180'
                        : ''
                    }`}
                  />
                </div>
                <div
                  className={`flex gap-5 items-center overflow-hidden transition-all duration-500 ease-in-out ${instance.state !== 'running' && 'hidden'} ${
                    expandedInstances.includes(instance.id)
                      ? 'max-h-40 opacity-100'
                      : 'max-h-0 opacity-0'
                  }`}
                >
                  <code className="block bg-gray-800 p-2 rounded mt-2 w-fit">
                    ssh {user.username}@{instance.hostname}
                  </code>
                  <button
                    onClick={() =>
                      handleCopy(`ssh ${user.username}@${instance.hostname}`)
                    }
                  >
                    <ContentCopyIcon className="text-white hover:text-gray-400 cursor-pointer" />
                  </button>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="grid justify-center items-center py-5 ">
          <div className="grid justify-center">
            <PlaylistRemoveIcon
              sx={{
                scale: '2.5',
              }}
            />
          </div>
          <p className="lg:text-[35px] md:text-[28px] sm:text-[22px] text-[18px] font-['Poppins'] p-5">
            No Active Instances Found
          </p>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
