import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid, type GridColDef, type GridRowsProp } from '@mui/x-data-grid';
import { Dialog, DialogTitle, Menu, MenuItem } from '@mui/material';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MailIcon from '@mui/icons-material/Mail';
import type UserContextType from '../../interfaces/auth-context';
import { UserContext } from '../../contexts/UserContext';

type Inputs = {
  name: string;
  email: string;
  role: string;
};
type RowDataType = {
  id: number;
  username: string;
  name: string;
  email: string;
  phoneNumber: string;
  role: string;
  createdAt: string;
  updatedAt: string;
  isInvitationAccepted: null | boolean;
};

function Team() {
  const [fetchingMembersData, setFetchingMembersData] = useState<boolean>(true);
  const [openInviteMemberModal, setOpenInviteMemberModal] =
    useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [teamMembersData, setTeamMembersData] = useState<GridRowsProp>([]);
  const [rowData, setRowData] = useState<RowDataType>();
  const [editMember, setEditMember] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { user } = useContext<UserContextType>(UserContext);
  const authToken = localStorage.getItem('authToken');
  const openMenu = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<Inputs>();

  const columns: GridColDef<(typeof teamMembersData)[number]>[] = [
    {
      field: 'name',
      headerName: 'NAME',
      flex: 1 / 2,
      editable: false,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'email',
      headerName: 'EMAIL',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
      editable: false,
    },
    {
      field: 'role',
      headerName: 'ROLE',
      flex: 1 / 5,
      headerClassName: 'super-app-theme--header',
      editable: false,
      sortable: false,
      headerAlign: 'center',
      renderCell(params) {
        return (
          <div className="flex justify-center">
            {params.row.role === 'ADMIN' && 'Admin'}
            {params.row.role === 'DEVELOPER' && 'Developer'}
            {params.row.role === 'VIEWER' && 'Viewer'}
          </div>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1 / 5,
      headerClassName: 'super-app-theme--header',
      editable: false,
      sortable: false,
      headerAlign: 'center',
      renderCell(params) {
        return (
          <div className="flex justify-center">
            {params.row.isInvitationAccepted === false && 'Pending'}
            {params.row.isInvitationAccepted === true && 'Accepted'}
            {params.row.isInvitationAccepted === null && '-'}
          </div>
        );
      },
    },
    {
      field: '',
      headerName: 'Actions',
      width: 75,
      headerClassName: 'super-app-theme--header',
      editable: false,
      sortable: false,
      renderCell(params: any) {
        return (
          <div className="flex justify-end">
            <div
              className="cursor-pointer"
              onClick={(e) => {
                handleMenuClick(e);
                setRowData(params.row);
              }}
            >
              <MoreVertIcon />
            </div>
          </div>
        );
      },
    },
  ];

  const fetchTeamData = async () => {
    setFetchingMembersData(true);
    axios
      .get(process.env.REACT_APP_API_URL + '/teams/members', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        setTeamMembersData(res.data.data);
        setFetchingMembersData(false);
      })
      .catch((err) => {
        setFetchingMembersData(false);
      });
  };

  const inviteOrUpdateMember = async (data: Inputs) => {
    setIsLoading(true);
    try {
      if (editMember && rowData) {
        const res = await axios.put(
          process.env.REACT_APP_API_URL + `/teams/member/${rowData.id}`,
          data,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        toast.success(res.data.message);
      } else {
        const res = await axios.post(
          process.env.REACT_APP_API_URL + '/teams/member',
          data,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        toast.success(res.data.message);
      }
      fetchTeamData();
    } catch (err: any) {
      toast.error(err.response?.data?.message || 'An error occurred');
    } finally {
      setOpenInviteMemberModal(false);
      setIsLoading(false);
    }
  };

  const handleEditClick = () => {
    setEditMember(true);
    setOpenInviteMemberModal(true);
    if (rowData) {
      setValue('name', rowData.name);
      setValue('email', rowData.email);
      setValue('role', rowData.role);
    }
    handleMenuClose();
  };

  const handleAddClick = () => {
    setEditMember(false);
    reset();
    setOpenInviteMemberModal(true);
  };

  const sendInvitation = async () => {
    axios
      .post(
        process.env.REACT_APP_API_URL + '/teams/resend-invitation',
        {
          email: rowData?.email,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
    handleMenuClose();
  };

  const deleteMember = async () => {
    axios
      .delete(process.env.REACT_APP_API_URL + '/teams/member/' + rowData?.id, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        fetchTeamData();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
    setOpenDeleteModal(false);
  };

  useEffect(() => {
    fetchTeamData();
  }, []);

  return (
    <div className="bg-black">
      <div className="flex justify-between items-center">
        <div className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[20px]">
          {user.teamName}
        </div>
        <button
          className="font-['Poppins'] bg-[#8B5CF6] rounded-[5px] sm:h-[47px] sm:w-[91px] h-[29px] w-[85px]"
          onClick={handleAddClick}
        >
          Invite
        </button>
      </div>
      <DataGrid
        rows={teamMembersData}
        columns={columns}
        disableColumnMenu
        disableColumnResize
        disableDensitySelector
        disableColumnFilter
        disableRowSelectionOnClick
        hideFooter
        loading={fetchingMembersData}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
        className="text-white"
        sx={{
          color: 'white',
          border: 'none',
          fontFamily: 'Poppins',
          '& .super-app-theme--header': {
            backgroundColor: '#000',
            color: 'white',
          },
          '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderTop: 'none',
          },
          '& .MuiDataGrid-iconSeparator': {
            display: 'none',
          },
          '& .MuiSkeleton-root': {
            backgroundColor: '#8B5CF6',
          },
          '& .MuiDataGrid-overlay': {
            backgroundColor: 'black',
          },
          '& .MuiCircularProgress-root': {
            color: '#8B5CF6',
          },
        }}
      />
      <Dialog
        open={openInviteMemberModal}
        onClose={() => setOpenInviteMemberModal(false)}
        aria-labelledby="responsive-dialog-title"
        sx={{
          '.css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
            borderRadius: '20px',
            backgroundColor: '#000',
            border: '2px solid #8B5CF6',
            width: '500px',
          },
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className="text-white text-center"
        >
          {editMember ? 'Edit Member Details' : 'Enter Member Details'}
        </DialogTitle>
        <div className="m-10">
          <form
            onSubmit={handleSubmit(inviteOrUpdateMember)}
            className="grid justify-center w-full"
          >
            <input
              className="border custom-input border-[#505050] rounded-[10px] h-[46px] text-[#B7B7B7] md:text-[14px] sm:text-[12px] text-[10px] px-[2px] cursor-pointer my-2 bg-black p-5 custom-small:w-[350px] w-[250px]"
              type="text"
              placeholder="Name"
              {...register('name', {
                required: true,
              })}
            />
            {errors.name && <p className="text-red-500">Name is required</p>}
            <input
              className="border custom-input border-[#505050] rounded-[10px] h-[46px] text-[#B7B7B7] md:text-[14px] sm:text-[12px] text-[10px] px-[2px] cursor-pointer my-2 bg-black p-5 custom-small:w-[350px] w-[250px]"
              type="email"
              placeholder="Email"
              {...register('email', {
                required: true,
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                  message: 'Please enter a valid email address',
                },
              })}
            />
            {errors.email && (
              <p className="text-red-500">{errors.email?.message}</p>
            )}
            <select
              style={{ color: '#B7B7B7' }}
              className="border custom-input border-[#505050] rounded-[10px] h-[46px]  md:text-[14px] sm:text-[12px] text-[10px] px-[2px] cursor-pointer my-2 bg-black custom-small:w-[350px] customw-[250px]"
              id="selectmethod"
              defaultValue=""
              {...register('role', { required: true })}
            >
              <option value="" disabled>
                Select user role
              </option>
              <option value="ADMIN" disabled>
                Admin
              </option>
              <option value="VIEWER" className="text-white">
                Viewer
              </option>
              <option value="DEVELOPER" className="text-white">
                Developer
              </option>
            </select>
            {errors.role && (
              <p className="text-red-500">You must select an option!</p>
            )}
            <div className="flex justify-center gap-5 w-full">
              <button className="bg-[#8B5CF6] w-2/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px] font-bold">
                {isLoading ? (
                  <div className="flex justify-center items-center">
                    <div className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full border-white border-t-transparent mr-2"></div>
                  </div>
                ) : editMember ? (
                  'Update'
                ) : (
                  'Add'
                )}
              </button>
              <button
                className="bg-[#8B5CF6] text-white w-2/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px]"
                onClick={() => setOpenInviteMemberModal(false)}
                type="button"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Dialog>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            width: '20ch',
            backgroundColor: '#000',
            border: '2px solid #8B5CF6',
            color: '#fff',
          },
        }}
      >
        <MenuItem key={'Update'} onClick={handleEditClick}>
          <div
            style={{ display: 'flex', alignItems: 'center', color: 'green' }}
          >
            <EditIcon style={{ marginRight: '8px', color: 'green' }} />
            Update
          </div>
        </MenuItem>
        {rowData?.isInvitationAccepted !== null && (
          <MenuItem
            key={'Delete'}
            onClick={() => {
              setOpenDeleteModal(true);
              handleMenuClose();
            }}
          >
            <div
              style={{ display: 'flex', alignItems: 'center', color: 'red' }}
            >
              <DeleteIcon style={{ marginRight: '8px', color: 'red' }} />
              Delete
            </div>
          </MenuItem>
        )}
        {rowData?.isInvitationAccepted === false && (
          <MenuItem key={'Send Invitation'} onClick={sendInvitation}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <MailIcon
                style={{
                  marginRight: '8px',
                }}
              />
              Send Invitation
            </div>
          </MenuItem>
        )}
      </Menu>
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="responsive-dialog-title"
        sx={{
          '.css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
            borderRadius: '20px',
            backgroundColor: '#000',
            border: '2px solid #8B5CF6',
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title" className="text-white">
          {'Are you sure you want to delete this account?'}
        </DialogTitle>
        <div className="flex justify-center gap-5">
          <button
            className="bg-[#8B5CF6]  w-1/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px] text-red-700 font-bold"
            onClick={deleteMember}
          >
            <DeleteIcon />
            Yes
          </button>
          <button
            className="bg-[#8B5CF6] text-white w-1/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px]"
            onClick={() => setOpenDeleteModal(false)}
          >
            No
          </button>
        </div>
      </Dialog>
    </div>
  );
}

export default Team;
