import { FC, ReactNode } from 'react';
import { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
import ForbiddenPage from '../ForbiddenPage';

interface ProtectedRouteProps {
  children: ReactNode;
  allowedRoles: string[];
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
  children,
  allowedRoles,
}) => {
  const { user } = useContext(UserContext);

  if (!user || !allowedRoles.includes(user.role)) {
    return <ForbiddenPage />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
