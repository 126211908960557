import { useContext, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { toast } from 'sonner';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import background from '../../assets/Pixlated-bg.png';
import { ReactComponent as StripeIcon } from '../../assets/stripeIcon.svg';
import { ReactComponent as CoinbaseIcon } from '../../assets/coinbaseIcon.svg';
import type UserContextType from '../../interfaces/auth-context';
import { UserContext } from '../../contexts/UserContext';
import CheckoutForm from '../StripeForm/checkoutForm';
import './index.css';

function ProfileMenu() {
  const [tabValue, setTabValue] = useState<number>(0);
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUpdateUsernameModal, setOpenUpdateUsernameModal] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [openStripe, setOpenStripe] = useState(false);
  const { setLoading, getUser, user } =
    useContext<UserContextType>(UserContext);
  const [username, setUsername] = useState<string>(user.username);
  const [credits, setCredits] = useState<number | null>(null);

  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

  const handleClickOpen = () => {
    setOpenDeleteModal(true);
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  const handleCredits = (credits: string) => {
    if (credits === '' || credits === ' ') {
      setCredits(null);
    }
    setCredits(Number(credits));
  };

  const updateUserName = async () => {
    axios
      .patch(
        process.env.REACT_APP_API_URL + '/users/change-username',
        {
          username,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setOpenUpdateUsernameModal(false);
        getUser();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setOpenUpdateUsernameModal(false);
      });
  };

  const deleteAccount = async () => {
    setLoading(true);
    axios
      .delete(process.env.REACT_APP_API_URL + '/users/delete-account', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        localStorage.removeItem('authToken');
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
    setOpenDeleteModal(false);
  };

  const changePassword = async () => {
    setLoading(true);
    axios
      .post(
        process.env.REACT_APP_API_URL + '/users/change-password',
        {
          existingPassword: currentPassword,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
        setOpenChangePasswordModal(false);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  const handleCoinbasePaymentPage = (hosted_url: string) => {
    window.location.href = hosted_url;
  };

  const handleCoinbasePayment = () => {
    if (credits) {
      axios
        .post(
          process.env.REACT_APP_API_URL + '/coinbase/create-charge',
          {
            charge: credits * 10,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
          }
        )
        .then((res) => {
          const hostedUrl = res.data.data.hosted_url;
          if (hostedUrl) {
            handleCoinbasePaymentPage(hostedUrl);
          } else {
            toast.error('No hosted URL found in response.');
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  useEffect(() => {
    let url = window.location.href;
    const statusMatch = url.match(/[?&]success=([^&]+)/);
    const status = statusMatch ? statusMatch[1] : null;

    if (status) {
      if (status === 'true') {
        toast.success(
          'Payment Successful, payment will be verified in 5-10 minutes.'
        );
      } else {
        toast.error('Payment Failed, please try again.');
      }
      url = url.replace(/[?&]success=[^&]+/, '');
      url = url.replace(/[?&]$/, '');
      window.history.replaceState(null, '', url);
    }
  }, []);

  return (
    <div className="w-full">
      <div className="pb-5 pt-2 flex custom-small:justify-start justify-center">
        Profile
      </div>
      <div
        className="relative w-full h-fit custom-small:rounded-[41px] rounded-[16px]"
        style={{
          border: '1px solid rgba(255, 255, 255, 0.5)',
          overflow: 'hidden',
          background: `linear-gradient(126.04deg, rgba(139, 92, 246, 0.3) -61.58%, rgba(0, 0, 0, 0.11) 65.87%)`,
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: `url('${background}') center/cover no-repeat`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity: 0.04,
            borderRadius: 'inherit',
            display: 'grid',
            zIndex: 0,
          }}
        />
        <div className="flex z-10 relative justify-between md:m-10 sm:m-5 m-2 border border-[#8B5CF6] rounded-[50px] h-[46px] text-[#6F6F6F] md:text-[14px] gap-1 text-[12px] px-[2px] cursor-pointer">
          <div
            className={`${tabValue === 0 ? 'bg-[#8B5CF6] text-white' : ''} flex justify-center items-center rounded-[50px] px-5 w-full font-['Poppins'] my-[2px] text-button`}
            onClick={() => setTabValue(0)}
          >
            Account
          </div>
          {user.role === 'ADMIN' && (
            <div
              className={`${tabValue === 1 ? 'bg-[#8B5CF6] text-white' : ''} flex font-['Poppins'] justify-center items-center rounded-[50px] px-5 w-full my-[2px] text-button`}
              onClick={() => setTabValue(1)}
            >
              Credits
            </div>
          )}
          {/* <div
            className={`${tabValue === 2 ? 'bg-[#8B5CF6] text-white' : ''} flex justify-center items-center rounded-[50px] px-5 w-full font-['Poppins'] my-[2px] text-button`}
            onClick={() => setTabValue(2)}
          >
            Referral
          </div> */}
        </div>
        <div>
          {/* {tabValue !== 1 && (
            <div className="md:m-10 sm:m-5 m-2 font-['Poppins'] text-[#ABABAB] md:text-[16px] sm:text-[14px] text-[10px]">
              Jumpstart your journey with Paal.AI by setting a unique username!
              Not only does it carve out your distinct identity in
              <span className="text-[#8B5CF6]"> our vibrant community </span>,
              but it also kicks off your earning streak. Set your username now
              and instantly
              <span className="text-[#8B5CF6]"> earn an extra 5 </span>points to
              boost your progress. Your username is more than just a name; it’s
              your first step towards unlocking exclusive rewards and
              opportunities. Don’t miss out – claim your points and make your
              mark!
            </div>
          )} */}
          {tabValue === 0 && (
            <div className="z-10 relative md:m-10 sm:m-5 m-2 grid">
              <span className="custom-small:text-[20px] text-[14px]">
                Username
              </span>
              <input
                className="border custom-input border-[#505050] rounded-[10px] h-[46px] text-[#B7B7B7] md:text-[14px] sm:text-[12px] text-[10px] px-[2px] cursor-pointer my-2 bg-black p-5 max-w-[300px]"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                type="text"
                placeholder="Enter your username"
              />
              <button
                className="bg-[#8B5CF6] text-white max-w-[120px] rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px]"
                onClick={() =>
                  username !== '' && setOpenUpdateUsernameModal(true)
                }
              >
                Update
              </button>
              <div className="pt-5 custom-small:text-[20px] text-[14px]">
                Password
              </div>
              <div className="text-[#787878] font-['Poppins'] py-2 custom-small:text-[16px] text-[10px]">
                Changing your password will sign you out of all other sessions.
              </div>
              <button
                className="bg-[#8B5CF6] text-black custom-small:max-w-[535px] max-w-[218px] custom-small:h-[55px] h-[43px] rounded-2xl p-2 my-5 font-[600] font-['Poppins'] custom-small:text-[16px] text-[10px]"
                onClick={() => setOpenChangePasswordModal(true)}
              >
                Change password
              </button>
              <div className="pt-5 custom-small:text-[20px] text-[14px]">
                Close account
              </div>
              <div className="text-[#787878] font-['Poppins'] py-2 max-w-[578px] custom-small:text-[16px] text-[10px]">
                After closing your account, you and any team members associated
                with your account will no longer be able to log in and your data
                will be lost.
              </div>
              <button
                className="bg-[#8B5CF6] text-black custom-small:max-w-[535px] max-w-[218px] custom-small:h-[55px] h-[43px] rounded-2xl p-2 my-5 font-[600] font-['Poppins'] custom-small:text-[16px] text-[10px]"
                onClick={handleClickOpen}
              >
                Close my account
              </button>
            </div>
          )}
          {tabValue === 1 && user.role === 'ADMIN' && (
            <div className="z-10 relative md:m-10 sm:m-5 m-2 grid select-none">
              <span className="custom-small:text-[20px] text-[14px] text-[#ABABAB] py-3">
                Your Credit Balance:
              </span>
              <div className="border border-[#505050] max-w-fit h-[102px] px-2 rounded-[10px] flex justify-center items-center">
                <div>
                  <span className="lg:text-[64px] md:text-[50px] text-[30px] px-3">
                    {parseFloat(user.credits.toString()).toFixed(2)}
                  </span>
                  <span className="lg:text-[40px] md:text-[30px] text-[20px] text-[#737373]">
                    Credits
                  </span>
                </div>
              </div>
              <span className="custom-small:text-[20px] text-[14px] py-3">
                Buy Credits
              </span>
              <div className="relative max-w-[539px] h-[54px]">
                <input
                  type="number"
                  value={credits ? credits : ''}
                  onChange={(e) => handleCredits(e.target.value)}
                  className="w-full h-full rounded-[10px] border border-[#505050] bg-black flex items-center px-5 pr-[60px] text-white"
                  placeholder="1 Credit/$10 USD"
                />
                {credits
                  ? credits > 0 && (
                      <div className="absolute right-5 top-1/2 transform -translate-y-1/2 text-white">
                        ${credits * 10} USD
                      </div>
                    )
                  : ''}
              </div>
              <div className="flex gap-3 py-5">
                <div
                  className={`${credits ? 'bg-[#8B5CF6] cursor-pointer' : 'bg-[#4C4C4C] cursor-not-allowed'} custom-small:h-[58px] h-[24px] items-center justify-center custom-small:w-[122px] w-[65px] flex custom-small:p-5 p-3 rounded-lg`}
                  onClick={() => credits && setOpenStripe(true)}
                >
                  <StripeIcon />
                </div>
                <div
                  className={`${credits ? 'bg-[#8B5CF6] cursor-pointer' : 'bg-[#4C4C4C] cursor-not-allowed'} custom-small:h-[58px] h-[24px] items-center justify-center custom-small:w-[122px] w-[65px] flex custom-small:p-5 p-3 rounded-lg`}
                  onClick={() => credits && handleCoinbasePayment()}
                >
                  <CoinbaseIcon />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-full border-b-2 border-b-[#4C4C4C] py-5 mb-3"></div>
      {/* <div className="flex custom-small:gap-4 gap-2 pt-10 pb-5 items-center w-full">
        <div className="flex z-10 relative custom-small:max-w-[321px] max-w-[238px] border border-[#8B5CF6] rounded-[50px] h-[46px] text-white md:text-[14px] gap-1 text-[12px] px-[2px] cursor-pointer">
          <div className="flex justify-center items-center rounded-[50px] px-5  font-['Poppins'] my-[2px] font-[600] text-button">
            Status
          </div>
          <div className="flex justify-center items-center font-['Poppins'] rounded-[50px] px-5 w-full my-[2px] font-[600] bg-[#8B5CF6] text-black text-button">
            All services online
          </div>
        </div>
        <TwitterIcon />
        <TelegramIcon />
      </div> */}
      <span className='text-[#3D3D3D] font-["Poppins"] custom-small:text-[16px] text-[10px]'>
        © Copyright Deploy.paal
      </span>
      <Dialog
        open={openDeleteModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '20px',
            backgroundColor: '#000',
            border: '2px solid #8B5CF6',
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title" className="text-white">
          {'Are you sure you want to delete this account?'}
        </DialogTitle>
        <div className="flex justify-center gap-5">
          <button
            className="bg-[#8B5CF6]  w-1/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px] text-red-700 font-bold"
            onClick={deleteAccount}
          >
            <DeleteIcon />
            Yes
          </button>
          <button
            className="bg-[#8B5CF6] text-white w-1/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px]"
            onClick={handleClose}
          >
            No
          </button>
        </div>
      </Dialog>
      <Dialog
        open={openUpdateUsernameModal}
        onClose={() => setOpenUpdateUsernameModal(false)}
        aria-labelledby="responsive-dialog-title"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '20px',
            backgroundColor: '#000',
            border: '2px solid #8B5CF6',
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title" className="text-white">
          {'Are you sure you want to update the username?'}
        </DialogTitle>
        <div className="flex justify-center gap-5">
          <button
            className="bg-[#8B5CF6]  w-1/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px] font-bold"
            onClick={updateUserName}
          >
            Yes
          </button>
          <button
            className="bg-[#8B5CF6] text-white w-1/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px]"
            onClick={() => setOpenUpdateUsernameModal(false)}
          >
            No
          </button>
        </div>
      </Dialog>
      <Dialog
        open={openStripe}
        onClose={() => setOpenStripe(false)}
        aria-labelledby="responsive-dialog-title"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '20px',
            backgroundColor: '#000',
            border: '2px solid #8B5CF6',
            width: '500px',
          },
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className="text-white text-center"
        >
          {'Enter Card Details'}
        </DialogTitle>
        <div className="m-10">
          <Elements stripe={stripePromise}>
            <CheckoutForm credits={credits} setOpenStripe={setOpenStripe} />
          </Elements>
        </div>
      </Dialog>
      <Dialog
        open={openChangePasswordModal}
        onClose={() => setOpenChangePasswordModal(false)}
        aria-labelledby="responsive-dialog-title"
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '20px',
            backgroundColor: '#000',
            border: '2px solid #8B5CF6',
            width: '300px',
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title" className="text-white">
          {'Change Password'}
        </DialogTitle>
        <div className="grid justify-center w-full">
          <input
            className="border w-full custom-input border-[#505050] rounded-[10px] h-[46px] text-[#B7B7B7] md:text-[14px] sm:text-[12px] text-[10px] px-[2px] cursor-pointer my-2 bg-black p-5 max-w-[300px]"
            type="password"
            placeholder="Current Password"
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <input
            className="border w-[250px] custom-input border-[#505050] rounded-[10px] h-[46px] text-[#B7B7B7] md:text-[14px] sm:text-[12px] text-[10px] px-[2px] cursor-pointer my-2 bg-black p-5 max-w-[300px]"
            type="password"
            placeholder="New Password"
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <input
            className="border custom-input border-[#505050] rounded-[10px] h-[46px] text-[#B7B7B7] md:text-[14px] sm:text-[12px] text-[10px] px-[2px] cursor-pointer my-2 bg-black p-5 max-w-[300px]"
            type="password"
            placeholder="Confirm New Password"
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
          {newPassword !== confirmNewPassword && (
            <p className="text-red-700 text-[11px]">Password does not match</p>
          )}
          <div className="flex justify-center gap-5 w-full">
            <button
              className="bg-[#8B5CF6] w-2/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px] font-bold"
              disabled={
                newPassword !== confirmNewPassword ||
                newPassword === '' ||
                confirmNewPassword === ''
              }
              onClick={changePassword}
            >
              Update
            </button>
            <button
              className="bg-[#8B5CF6] text-white w-2/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px]"
              onClick={() => setOpenChangePasswordModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default ProfileMenu;
