import { useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import { toast } from 'sonner';
import Logo from '../../assets/Logo.png';
import { ReactComponent as Profile } from '../../assets/profile.svg';
import { UserContext } from '../../contexts/UserContext';
import UserContextType from '../../interfaces/auth-context';

function Header({ handleDrawerToggle }: { handleDrawerToggle: () => void }) {
  const drawerWidth = 240;
  const navigate = useNavigate();
  const { user } = useContext<UserContextType>(UserContext);

  const handleLogout = async () => {
    axios
      .post(
        process.env.REACT_APP_API_URL + '/users/logout',
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      )
      .then((res) => {
        localStorage.removeItem('authToken');
        navigate('/login');
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        backgroundColor: '#0A0010',
        borderBottom: '1px solid #2D2D2D',
        height: 'max-content',
      }}
    >
      <div className="w-full flex justify-between px-2 py-2 custom-small:hidden ">
        <div className="flex items-center gap-3" onClick={() => navigate('/')}>
          <img src={Logo} alt="logo" width={20} height={20} />
          <h1 className="text-white md:text-[26px] text-[12px] font-[400] text-button">
            <strong>Deploy</strong>.paal
          </h1>
        </div>
        <div className="bg-[#0A0010]">
          <div className="flex gap-[10px] text-[10px] clash-display">
            {user.role === 'ADMIN' && (
              <button className="border border-[#FFFFFF1A] rounded-[6px] px-[10px] py-[6px] text-[##FFFFFF] bg-gradient-radial from-[#00000059] to-[#FFFFFF1A]">
                {parseFloat(user.credits.toString()).toFixed(2)}{' '}
                <span className="text-[#A5A5A5]">Credits</span>
              </button>
            )}
            {/* <button className="bg-[#8B5CF6] border border-[##FFFFFF4D] rounded-[6px] px-[10px] py-[6px]">
              0xgt5...tgh6
            </button> */}
            <button
              className="border border-[#FFFFFF1A] rounded-[6px] px-[10px] py-[6px] bg-gradient-radial from-[#00000059] to-[#FFFFFF1A]"
              onClick={handleLogout}
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          sx={{
            pr: 0,
            display: { sm: 'none' },
            justifyContent: 'space-between',
          }}
          className="w-full pointer-events-none	"
        >
          <MenuIcon
            sx={{ color: '#8B5CF6' }}
            onClick={handleDrawerToggle}
            className="pointer-events-auto"
          />
          <div className="flex items-center gap-2 pointer-events-none	">
            <Profile
              style={{
                stroke: '#8B5CF6 !important',
              }}
            />
            <span className="text-white text-[12px] clash-display">
              {user.name}
            </span>
          </div>
        </IconButton>
        <Typography
          variant="h6"
          noWrap
          component="div"
          className="md:flex justify-between w-full custom-small:grid hidden"
        >
          <div className="flex items-center gap-2">
            <Profile />
            <span className="text-white text-[20px] clash-display">
              {user.name}
            </span>
          </div>
          <div className="flex gap-[10px] lg:text-[14px] sm:text-[11px] text-[10px] clash-display">
            {user.role === 'ADMIN' && (
              <button className="border border-[#FFFFFF1A] rounded-[6px] lg:px-[24px] px-[10px] py-[6px] text-[##FFFFFF] bg-gradient-radial from-[#00000059] to-[#FFFFFF1A]">
                {parseFloat(user.credits.toString()).toFixed(2)}{' '}
                <span className="text-[#A5A5A5]">Credits</span>
              </button>
            )}
            {/* <button className="bg-[#8B5CF6] border border-[##FFFFFF4D] rounded-[6px] lg:px-[24px] px-[10px] py-[6px]">
              0xgt5...tgh6
            </button> */}
            <button
              className="border border-[#FFFFFF1A] rounded-[6px] lg:px-[24px] px-[10px] py-[6px] bg-gradient-radial from-[#00000059] to-[#FFFFFF1A]"
              onClick={handleLogout}
            >
              Sign Out
            </button>
          </div>
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
