import { useContext, useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Chip, CircularProgress } from '@mui/material';
import axios from 'axios';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import Grid from '../../assets/Grid.png';
import InstanceData, { InstanceType } from '../../interfaces/instanceData';
import type UsersWithSSHKeys from '../../interfaces/usersWithSSHKey';
import type SSHKey from '../../interfaces/sshKey';
import './index.css';
import { UserContext } from '../../contexts/UserContext';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
type UserDataWithSSHKey = {
  name: string;
  keyName: string;
  keyId: number;
  userId: number;
};

function CreateInstance({
  refetchInstances,
  setRefetchInstances,
}: {
  refetchInstances: boolean;
  setRefetchInstances: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [menu, setMenu] = useState<Number>(0);
  const [instanceType, setInstanceType] = useState<InstanceType | null>(null);
  const [instanceRegion, setInstanceRegion] = useState<string>('');
  const [instanceName, setInstanceName] = useState<string>('');
  const [durationInHours, setDurationInHours] = useState<number>(0);
  const [selectedUsers, setSelectedUsers] = useState<UserDataWithSSHKey[] | []>(
    []
  );
  const [instancesData, setInstancesData] = useState<InstanceData>();
  const [usersData, setUsersData] = useState<UserDataWithSSHKey[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { user, getUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleChange = (
    event: React.SyntheticEvent,
    sshKeys: UserDataWithSSHKey[]
  ) => {
    setSelectedUsers(sshKeys.filter((option: UserDataWithSSHKey) => !!option));
  };

  const validateNumberOfHours: () => boolean = () => {
    if (durationInHours <= 0) {
      toast.error('Please enter a valid duration in hours.');
      return false;
    }

    if (
      !user.credits ||
      (instanceType && instanceType.price * durationInHours > user?.credits)
    ) {
      toast.error('You do not have enough credits to create this instance.');
      return false;
    }

    return true;
  };

  const launchInstance = async () => {
    setIsLoading(true);
    const sshKeyIds = selectedUsers.map((user) => user.keyId);

    axios
      .post(
        process.env.REACT_APP_API_URL + '/instances',
        {
          name: instanceName,
          region: instanceRegion,
          instanceType: instanceType?.instanceType,
          sshKeyIds,
          durationInHours,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        navigate('/homepage/instances');
        setRefetchInstances(!refetchInstances);
        getUser();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchInstancesData = () => {
    axios
      .get(process.env.REACT_APP_API_URL + '/instances/available-options', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      })
      .then((res) => {
        setInstancesData(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchUsersData = () => {
    axios
      .get(process.env.REACT_APP_API_URL + '/users/users-with-ssh-keys', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      })
      .then((res) => {
        const formattedData = res.data.data.flatMap(
          (user: UsersWithSSHKeys) => {
            if (user.sshKeys.length === 0) {
              return [
                {
                  name: user.name,
                  keyName: 'No SSH Keys',
                  keyId: null,
                  userId: user.id,
                },
              ];
            } else {
              return user.sshKeys.map((key: SSHKey) => ({
                name: user.name,
                keyName: key.keyName,
                keyId: key.id,
                userId: user.id,
              }));
            }
          }
        );
        const adminKey = formattedData.find(
          (key: UserDataWithSSHKey) =>
            key.userId === user.id && key.keyId !== null
        );
        if (adminKey) setSelectedUsers([adminKey]);
        setUsersData(formattedData);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchInstancesData();
    fetchUsersData();
  }, []);

  return (
    <div
      className="bg-black h-auto min-h-screen bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `radial-gradient(circle, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 70%), url(${Grid})`,
      }}
    >
      <div className="flex justify-center font-[500] lg:text-[40px] md:text-[26px] sm:text-[20px] text-[20px]">
        GPU instances
      </div>
      <div className="flex justify-between items-center md:px-10 px-5">
        <div>
          <div
            className={`w-[16px] h-[16px] rounded-full ${menu === 0 ? ' bg-[#8B5CF6]' : 'bg-[#787878]'} cursor-pointer`}
            onClick={() => setMenu(0)}
          ></div>
        </div>
        <div className="w-full h-1 bg-[#787878]"></div>
        <div>
          <div
            className={`w-[16px] h-[16px] rounded-full bg-[#787878] cursor-pointer ${menu === 1 ? ' bg-[#8B5CF6]' : 'bg-[#787878]'}`}
            onClick={() => instanceType && setMenu(1)}
          ></div>
        </div>
        <div className="w-full h-1 bg-[#787878]"></div>
        <div>
          <div
            className={`w-[16px] h-[16px] rounded-full bg-[#787878] cursor-pointer ${menu === 2 ? ' bg-[#8B5CF6]' : 'bg-[#787878]'}`}
            onClick={() => instanceType && instanceRegion && setMenu(2)}
          ></div>
        </div>
      </div>
      <div className="flex gap-1 justify-between md:px-10 px-5 font-[500] lg:text-[20px] md:text-[14px] sm:text-[12px] text-[10px]">
        <div
          className={`${menu === 0 ? ' text-[#8B5CF6]' : 'text-[#787878]'} w-full`}
        >
          <div>Instance Type</div>
        </div>
        <div
          className={`${menu === 1 ? ' text-[#8B5CF6]' : 'text-[#787878]'} w-max text-center`}
        >
          <div>Region</div>
        </div>
        <div
          className={`${menu === 2 ? ' text-[#8B5CF6]' : 'text-[#787878]'} w-full text-right`}
        >
          <div>Manage Instance Access</div>
        </div>
      </div>
      <div className="flex justify-between md:px-10 px-5 font-[500] lg:text-[20px] md:text-[14px] sm:text-[12px] text-[10px]">
        <div className="text-[#787878] text-left flex-1">
          {instanceType ? instanceType.instanceType : ''}
        </div>
        <div className="text-[#787878] text-center flex-1">
          {instanceRegion}
        </div>
        <div className="text-[#787878] flex-1 text-right"></div>
      </div>
      {menu === 0 && (
        <div className="pt-10">
          <div className="flex justify-center items-center md:px-10 px-5 font-[500] lg:text-[32px] md:text-[25px] sm:text-[20px] text-[16px]">
            Select Instance type
          </div>
          {instancesData ? (
            <div className="grid lg:grid-cols-2 grid-cols-1">
              {instancesData.instanceTypes.map((item, index) => (
                <div className="py-5 px-2 flex-1" key={index}>
                  <div
                    className={`instance-box border ${instanceType?.instanceType === item.instanceType ? 'border-[2px] border-[#8B5CF6]' : 'border-[#ffffff99]'}`}
                  >
                    <div className="header font-['Poppins'] md:text-[20px] sm:text-[16px] text-[12px]">
                      <div>{item.instanceType}</div>
                      <div className="gap-2 flex items-center">
                        {/* <div>$27.92/hr</div> */}
                        <button
                          className="add-button h-[33px] w-[77px] hover:border hover:border-[#8B5CF6] custom-small:text-[10px] text-[10px] custom-small:rounded-[12px] rounded-[6px]"
                          onClick={() => {
                            setInstanceType(item);
                          }}
                        >
                          ADD
                        </button>
                      </div>
                    </div>
                    <div className="flex py-5 items-center custom-small:px-2 p-[10px]">
                      <div className="flex md:gap-8 sm:gap-5 gap-4 font-['Poppins'] custom-small:text-[12px] text-[8px] font-[600] text-[#ADADAD]">
                        <div className="grid">
                          <div>GPU</div>
                          <div>{item.gpu}</div>
                        </div>
                        <div className="grid">
                          <div>vCPUs</div>
                          <div>{item.vCPUs}</div>
                        </div>
                        <div className="grid">
                          <div>RAM</div>
                          <div>{item.memoryGiB} GiB</div>
                        </div>
                        <div className="grid">
                          <div>STORAGE</div>
                          <div>{item.instanceStorageGB}</div>
                        </div>
                        <div className="grid">
                          <div>PRICE</div>
                          <div>
                            {parseFloat(item.price.toString()).toFixed(2)} Cr /
                            hr
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex w-full justify-center py-20">
              <CircularProgress color="secondary" />
            </div>
          )}
          <div className="flex justify-center pt-5">
            <button
              className={`custom-small:h-[34px] custom-small:w-[79px] h-[27px] w-[68px] rounded-[10px] ${instanceType?.instanceType === '' ? 'bg-[#787878]' : 'bg-[#8B5CF6]'} font-['Poppins'] font-[500] text-white`}
              onClick={() => setMenu(1)}
              disabled={instanceType?.instanceType === ''}
            >
              Next
            </button>
          </div>
        </div>
      )}
      {menu === 1 && (
        <div className="pt-10">
          <div className="flex justify-center items-center md:px-10 px-5 font-[500] lg:text-[32px] md:text-[25px] sm:text-[20px] text-[16px]">
            Select Region
          </div>
          <div className="flex justify-center items-center md:px-10 px-5 font-[500] sm:text-[13px] text-[10px]">
            <div className="sm:w-[403px] text-center text-[#787878] font-['Poppins']">
              These are the regions available for the selected instance type.
              Regions closer to you have reduced latency.
            </div>
          </div>
          <div className="grid md:grid-cols-3 grid-cols-2 gap-5 py-5 text-[#787878]">
            {instancesData &&
              instancesData.regions.map((item, index) => (
                <div
                  className={`region-box ${instanceRegion === item && 'border-[2px] border-[#8B5CF6]'}`}
                  key={index}
                >
                  <div className='font-["Poppins"] font-[500] md:text-[24px] sm:text-[16px] text-[13px]'>
                    {item}
                  </div>
                  <div className="pt-3">
                    <button
                      className="add-button h-[33px] custom-small:w-[122px] w-[65px] custom-small:text-[16px] text-[10px] custom-small:rounded-[12px] rounded-[6px]"
                      onClick={() => setInstanceRegion(item)}
                    >
                      ADD
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <div className="flex justify-center pt-5">
            <button
              className={`custom-small:h-[34px] custom-small:w-[79px] h-[27px] w-[68px] rounded-[10px] ${instanceRegion === '' ? 'bg-[#787878]' : 'bg-[#8B5CF6]'} font-['Poppins'] font-[500] text-white`}
              onClick={() => setMenu(2)}
              disabled={instanceRegion === ''}
            >
              Next
            </button>
          </div>
        </div>
      )}
      {menu === 2 && (
        <div className="pt-10">
          <div className="flex justify-center items-center md:px-10 px-5 font-[500] lg:text-[32px] md:text-[25px] sm:text-[20px] text-[16px]">
            ASSIGN TEAM MEMBERS
          </div>
          <div className="text-center text-[#787878] md:text-[13px] text-[10px] font-['Poppins']">
            Select team members to grant access to this instance
          </div>
          <div className="grid w-full justify-center">
            <input
              type="text"
              className="border-b-2 py-5 custom-input outline-none"
              style={{
                background: 'none',
              }}
              placeholder="Enter instance name"
              onChange={(e) => setInstanceName(e.target.value)}
            />
            <input
              type="number"
              className="border-b-2 py-5 custom-input outline-none"
              style={{
                background: 'none',
              }}
              placeholder="Enter duration in hours"
              onChange={(e) => setDurationInHours(Number(e.target.value))}
            />
            {usersData && (
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={usersData}
                disableCloseOnSelect
                value={selectedUsers}
                ListboxProps={{
                  style: {
                    padding: '0px',
                  },
                }}
                onChange={handleChange}
                className="md:w-[500px]"
                sx={{
                  '& .MuiChip-root': {
                    backgroundColor: '#8B5CF6',
                    color: 'white',
                    fontWeight: '500',
                  },
                  '& .MuiSvgIcon-root, .MuiChip-deleteIcon': {
                    color: 'red !important',
                  },
                  '& .MuiInputBase-input, MuiOutlinedInput-input': {
                    color: 'white',
                    ':focus': {
                      outline: 'none',
                    },
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '0px !important',
                  },
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => {
                  const { ...optionProps } = props;
                  const isDisabled = option.keyId === null;

                  return (
                    <li
                      {...optionProps}
                      className={`bg-black text-white ${isDisabled ? 'opacity-50' : ''} cursor-pointer`}
                      style={{
                        border: '2px solid #8B5CF6',
                        pointerEvents: isDisabled ? 'none' : 'auto',
                      }}
                    >
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8, color: '#8B5CF6' }}
                        checked={selected}
                        disabled={isDisabled}
                      />
                      {option.name} {!isDisabled && `(${option.keyName})`}
                    </li>
                  );
                }}
                style={{ maxWidth: '500px', minWidth: '300px' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className='placeholder:font-["Poppins"]'
                    placeholder="Select team members"
                    sx={{
                      fontWeight: '500',
                      borderBottom: '1px solid #fff',
                      outline: 'none',
                      fontSize: '8px',
                    }}
                  />
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => {
                    const { ...tagProps } = getTagProps({ index });

                    return <Chip label={option.name} {...tagProps} />;
                  })
                }
              />
            )}
            <button
              className={`w-full text-black mt-5 h-[55px] rounded-[17px] text-[20px] font-['Poppins'] font-[600] ${instanceName === '' || selectedUsers.length === 0 ? 'bg-[#787878]' : 'bg-[#8B5CF6]'}`}
              disabled={instanceName === '' || selectedUsers.length === 0}
              onClick={() => {
                validateNumberOfHours() && launchInstance();
              }}
            >
              {isLoading ? (
                <div className="flex justify-center items-center">
                  <div className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full border-white border-t-transparent mr-2"></div>
                </div>
              ) : (
                'Launch Instance'
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateInstance;
