import BlockIcon from '@mui/icons-material/Block';

function ForbiddenPage() {
  return (
    <div style={{ color: 'red', textAlign: 'center' }} className='md:pt-52 sm:pt-32 pt-32'>
      <BlockIcon
        sx={{
          scale: '2.5',
        }}
      />
      <p className="lg:text-[40px] md:text-[32px] sm:text-[25px] text-[20px] font-['Poppins'] py-5">
        You do not have permission to access this page
      </p>
    </div>
  );
}

export default ForbiddenPage;
