import { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Toaster } from 'sonner';
import { Box, CircularProgress } from '@mui/material';
import LandingPage from './components/Landing';
import Login from './components/Login';
import Signup from './components/Signup';
import Homepage from './components/Homepage';
import Invitation from './components/Invitation';
import ResetPassword from './components/ResetPassword';
import { UserContext } from './contexts/UserContext';
import UserContextType from './interfaces/auth-context';
import './App.css';

function App() {
  const token = localStorage.getItem('authToken');
  const { loading, setLoading, getUser } =
    useContext<UserContextType>(UserContext);

  useEffect(() => {
    if (token) {
      getUser();
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <div className="App">
      <Toaster position="top-right" closeButton richColors />
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            backgroundColor: '#0A0010',
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/homepage/*" element={<Homepage />} />
          <Route path="/accept-invitation" element={<Invitation />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
