import {
  CircularProgress,
  Dialog,
  DialogTitle,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  DataGrid,
  type GridColDef,
  GridMoreVertIcon,
  type GridRowsProp,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import type SSHKey from '../../interfaces/sshKey';
import { ReactComponent as ReverseArrow } from '../../assets/reverse-arrow.svg';
import { UserContext } from '../../contexts/UserContext';

type RowDataType = {
  id: number;
  keyName: string;
  publicKey: string;
};
type Inputs = {
  keyName: string;
  publicKey: string;
};

function SSHKeys() {
  const [editKey, setEditKey] = useState<boolean>(false);
  const [openAddKeyModal, setOpenAddKeyModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openKeyInstructions, setOpenKeyInstructions] =
    useState<boolean>(false);
  const [rowData, setRowData] = useState<RowDataType>();
  const [keysData, setKeysData] = useState<GridRowsProp<SSHKey>>([]);
  const [fetchingKeysData, setFetchingKeysData] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const openMenu = Boolean(anchorEl);
  const authToken = window.localStorage.getItem('authToken');
  const { user } = useContext(UserContext);
  const columns: GridColDef<(typeof keysData)[number]>[] = [
    {
      field: 'keyName',
      headerName: 'Key Name',
      flex: 1 / 4,
      editable: false,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'publicKey',
      headerName: 'Public Key',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      sortable: false,
      editable: false,
    },
    {
      field: '',
      headerName: 'Actions',
      width: 75,
      headerClassName: 'super-app-theme--header',
      editable: false,
      sortable: false,
      renderCell(params: any) {
        return (
          <div className="flex justify-end">
            <div
              className="cursor-pointer"
              onClick={(e) => {
                handleMenuClick(e);
                setRowData(params.row);
              }}
            >
              <GridMoreVertIcon />
            </div>
          </div>
        );
      },
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<Inputs>();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setEditKey(true);
    setOpenAddKeyModal(true);
    if (rowData) {
      setValue('keyName', rowData.keyName);
      setValue('publicKey', rowData.publicKey);
    }
    handleMenuClose();
  };

  const handleAddClick = () => {
    setEditKey(false);
    reset();
    setOpenAddKeyModal(true);
  };

  const fetchAllKeys = async () => {
    setFetchingKeysData(true);
    axios
      .get(process.env.REACT_APP_API_URL + '/ssh-keys', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        setKeysData(res.data.data);
        setFetchingKeysData(false);
      })
      .catch(() => {
        setFetchingKeysData(false);
      });
  };

  const addOrUpdateKey = async (data: Inputs) => {
    setIsLoading(true);
    try {
      if (editKey && rowData) {
        const res = await axios.put(
          process.env.REACT_APP_API_URL + `/ssh-keys/${rowData.id}`,
          data,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        toast.success(res.data.message);
      } else {
        const res = await axios.post(
          process.env.REACT_APP_API_URL + '/ssh-keys',
          data,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        toast.success(res.data.message);
      }
      fetchAllKeys();
    } catch (err: any) {
      toast.error(err.response?.data?.message || 'An error occurred');
    } finally {
      setIsLoading(false);
      setOpenAddKeyModal(false);
    }
  };

  const deleteKey = async () => {
    axios
      .delete(process.env.REACT_APP_API_URL + '/ssh-keys/' + rowData?.id, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        fetchAllKeys();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
    setOpenDeleteModal(false);
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  useEffect(() => {
    fetchAllKeys();
  }, []);

  return (
    <div className="bg-black">
      <div className="flex justify-between items-center">
        <div className="lg:text-[40px] md:text-[32px] sm:text-[24px] text-[20px]">
          SSH Keys
        </div>
        <button
          className="font-['Poppins'] bg-[#8B5CF6] rounded-[5px] sm:h-[47px] sm:w-[91px] h-[29px] w-[85px]"
          onClick={handleAddClick}
        >
          Add Key
        </button>
      </div>
      {fetchingKeysData ? (
        <div className="flex w-full justify-center py-20">
          <CircularProgress color="secondary" />
        </div>
      ) : keysData.length === 0 ? (
        <div className="grid justify-center items-center py-5 md:pt-52 sm:pt-32 pt-32">
          <div className="grid justify-center">
            <PlaylistRemoveIcon
              sx={{
                scale: '2.5',
              }}
            />
          </div>
          <p className="lg:text-[35px] md:text-[28px] sm:text-[22px] text-[18px] font-['Poppins'] p-5">
            No Keys Found
          </p>
        </div>
      ) : (
        <DataGrid
          rows={keysData}
          columns={columns}
          disableColumnMenu
          disableColumnResize
          disableDensitySelector
          disableColumnFilter
          disableRowSelectionOnClick
          hideFooter
          loading={fetchingKeysData}
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
          className="text-white"
          sx={{
            color: 'white',
            border: 'none',
            fontFamily: 'Poppins',
            '& .super-app-theme--header': {
              backgroundColor: '#000',
              color: 'white',
            },
            '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell': {
              borderTop: 'none',
            },
            '& .MuiDataGrid-iconSeparator': {
              display: 'none',
            },
            '& .MuiSkeleton-root': {
              backgroundColor: '#8B5CF6',
            },
            '& .MuiDataGrid-overlay': {
              backgroundColor: 'black',
            },
            '& .MuiCircularProgress-root': {
              color: '#8B5CF6',
            },
          }}
        />
      )}

      <Dialog
        open={openAddKeyModal}
        onClose={() => setOpenAddKeyModal(false)}
        aria-labelledby="responsive-dialog-title"
        sx={{
          '.css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
            borderRadius: '20px',
            backgroundColor: '#000',
            border: '2px solid #8B5CF6',
            width: '500px',
          },
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className="text-white text-center"
        >
          {editKey ? 'Edit Key Details' : 'Enter Key Details'}
        </DialogTitle>
        <div className="m-10">
          <form
            onSubmit={handleSubmit(addOrUpdateKey)}
            className="grid justify-center w-full"
          >
            <input
              className="border custom-input border-[#505050] rounded-[10px] h-[46px] text-[#B7B7B7] md:text-[14px] sm:text-[12px] text-[10px] px-[2px] cursor-pointer my-2 bg-black p-5 custom-small:w-[350px] w-[250px]"
              type="text"
              placeholder="Key Name"
              {...register('keyName', {
                required: true,
                minLength: {
                  value: 3,
                  message: 'Key name must be at least 3 characters long',
                },
                maxLength: {
                  value: 50,
                  message: 'Key name must not exceed 50 characters',
                },
              })}
            />
            {errors.keyName && (
              <p className="text-red-500 custom-small:w-[350px] w-[250px]">
                {errors.keyName?.message}
              </p>
            )}
            <textarea
              className="border custom-input border-[#505050] rounded-[10px] text-[#B7B7B7] md:text-[14px] sm:text-[12px] text-[10px] px-[2px] cursor-pointer my-2 bg-black p-5 custom-small:w-[350px] w-[250px] h-[100px] resize-none"
              placeholder="Your public key"
              {...register('publicKey', {
                required: true,
              })}
            />
            {errors.publicKey && (
              <p className="text-red-500">{errors.publicKey?.message}</p>
            )}
            <p
              className="text-[#8B5CF6] text-sm mt-2 cursor-pointer"
              onClick={() => setOpenKeyInstructions(true)}
            >
              How to generate a public key?
            </p>
            <div className="flex justify-center gap-5 w-full">
              <button className="bg-[#8B5CF6] w-2/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px] font-bold">
                {isLoading ? (
                  <div className="flex justify-center items-center">
                    <div className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full border-white border-t-transparent mr-2"></div>
                  </div>
                ) : editKey ? (
                  'Update'
                ) : (
                  'Add'
                )}
              </button>
              <button
                className="bg-[#8B5CF6] text-white w-2/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px]"
                onClick={() => setOpenAddKeyModal(false)}
                type="button"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
        <Dialog
          open={openKeyInstructions}
          onClose={() => setOpenKeyInstructions(false)}
          sx={{
            '.css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
              borderRadius: '20px',
              backgroundColor: '#000',
              border: '2px solid #8B5CF6',
              width: '500px',
            },
          }}
        >
          <DialogTitle className="text-white bg-black grid gap-3">
            <ReverseArrow
              onClick={() => setOpenKeyInstructions(false)}
              className="cursor-pointer"
            />
            How to Generate a Public Key
          </DialogTitle>
          <div className="p-5 bg-black">
            <p className="text-white mb-3">
              Follow these steps to generate a public key using your
              terminal/command prompt:
            </p>
            <ol className="list-decimal list-inside text-white">
              <li>Open your terminal (Linux/macOS) or Git Bash (Windows).</li>
              <li>
                Run the following command to generate an RSA key pair:
                <div className="flex items-center gap-2">
                  <code className="block bg-gray-800 p-2 rounded mt-2 mb-2">
                    ssh-keygen -t rsa -b 4096 -C "{user.email}"
                  </code>
                  <button
                    onClick={() =>
                      handleCopy(`ssh-keygen -t rsa -b 4096 -C "${user.email}"`)
                    }
                  >
                    <ContentCopyIcon className="text-white hover:text-gray-400 cursor-pointer" />
                  </button>
                </div>
              </li>
              <li>Press Enter to save the key in the default file location.</li>
              <li>
                Enter a secure passphrase when prompted, or press Enter to skip.
              </li>
              <li>
                Your public key will be stored in the file{' '}
                <code>~/.ssh/id_rsa.pub</code>.
              </li>
              <li>
                To view and copy the public key, run:
                <div className="flex items-center gap-2">
                  <code className="block bg-gray-800 p-2 rounded mt-2">
                    cat ~/.ssh/id_rsa.pub
                  </code>
                  <button onClick={() => handleCopy(`cat ~/.ssh/id_rsa.pub`)}>
                    <ContentCopyIcon className="text-white hover:text-gray-400 cursor-pointer" />
                  </button>
                </div>
              </li>
            </ol>
            <div className="flex justify-center">
              <button
                className="bg-[#8B5CF6] text-white w-2/3 rounded-2xl p-2 my-5"
                onClick={() => setOpenKeyInstructions(false)}
              >
                Back
              </button>
            </div>
          </div>
        </Dialog>
      </Dialog>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            width: '20ch',
            backgroundColor: '#000',
            border: '2px solid #8B5CF6',
            color: '#fff',
          },
        }}
      >
        <MenuItem key={'Update'} onClick={handleEditClick}>
          <div
            style={{ display: 'flex', alignItems: 'center', color: 'green' }}
          >
            <EditIcon style={{ marginRight: '8px', color: 'green' }} />
            Update
          </div>
        </MenuItem>
        <MenuItem
          key={'Delete'}
          onClick={() => {
            setOpenDeleteModal(true);
            handleMenuClose();
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
            <DeleteIcon style={{ marginRight: '8px', color: 'red' }} />
            Delete
          </div>
        </MenuItem>
      </Menu>
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="responsive-dialog-title"
        sx={{
          '.css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
            borderRadius: '20px',
            backgroundColor: '#000',
            border: '2px solid #8B5CF6',
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title" className="text-white">
          {'Are you sure you want to delete this key?'}
        </DialogTitle>
        <div className="flex justify-center gap-5">
          <button
            className="bg-[#8B5CF6]  w-1/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px] text-red-700 font-bold"
            onClick={deleteKey}
          >
            <DeleteIcon />
            Yes
          </button>
          <button
            className="bg-[#8B5CF6] text-white w-1/3 rounded-2xl p-2 my-5 custom-small:text-[16px] text-[10px]"
            onClick={() => setOpenDeleteModal(false)}
          >
            No
          </button>
        </div>
      </Dialog>
    </div>
  );
}

export default SSHKeys;
