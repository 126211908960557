import { useNavigate } from 'react-router-dom';
import BittensorBg from '../../assets/bittensor-bg.png';
import ETHBg from '../../assets/ETHValidator.png';
import FineTuningBg from '../../assets/FineTuningBg.png';
import StableDifussionBg from '../../assets/Stable-Difussion-Bg.png';
import LLMBg from '../../assets/LLMBg.png';
import SolanaBg from '../../assets/SolanaBg.png';
import AIInferenceBg from '../../assets/AIInferenceBg.png';
import BitcoinBg from '../../assets/BitcoinBg.png';
import IPFSBg from '../../assets/IPFSBg.png';
import AIPruningBg from '../../assets/AIPruningBg.png';
import BittensorLogo from '../../assets/bittensor-tao-logo.png';
import ETHLogo from '../../assets/ETHLogo.png';
import SolanaLogo from '../../assets/solana logo.png';
import BitcoinLogo from '../../assets/bitcoin128 logo.png';

const Templates = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="lg:text-[40px] md:text-[26px] sm:text-[20px] text-[20px]">
          Templates
        </div>
        <div className="flex gap-3">
          <button className="max-h-[33px] lg:text-[16px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-5 md:px-4 sm:px-3 px-1 border-[#cacacc] bg-black md:w-[141px] w-[80px] bg-gradient-radial from-[#00000059] to-[#FFFFFF1A]">
            Running
          </button>
          <button className="md:h-[33px] lg:text-[16px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-5 md:px-4 sm:px-3 px-1 border-[#4E00FF] bg-[#8B5CF6] md:w-[141px] w-[80px]">
            All
          </button>
        </div>
      </div>
      <div className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-10 p-10">
        <div className="bg-gradient-radial from-[#00000059] to-[#FFFFFF1A] text-center font-['Poppins'] rounded-[23px] border border-[#848484] max-w-[340px] mx-auto">
          <div className="relative">
            <div className="absolute h-full w-full flex items-center justify-center z-10">
              <div className="flex flex-col items-center">
                <img
                  src={BittensorLogo}
                  alt="Bittensor Mining"
                  className=" w-[33px] h-[37px]"
                />
                <div className="text-center text-[20px] font-semibold clash-display">
                  Bittensor Mining
                </div>
              </div>
            </div>
            <img src={BittensorBg} alt="asd" className="w-full relative" />
          </div>
          <div className="grid justify-center border border-transparent border-b-[#8B5CF6] bg-black py-2 border-opacity-20">
            <div className="text-[10px]">g4dn.16xlarge</div>
            <div className="text-[#555555] text-[11px]">Recommended GPU</div>
          </div>
          <div className="grid justify-center border border-transparent border-b-[#848484] bg-black py-2">
            <div className="text-[10px]">72 Hours</div>
            <div className="text-[#555555] text-[11px]">Recommended Hours</div>
          </div>
          <div className="flex gap-2 justify-center py-3 bg-black rounded-b-[23px]">
            <button
              className="max-h-[33px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-2 md:px-4 sm:px-3 px-1 border-[#cacacc] bg-black md:w-[122px] w-[80px] bg-gradient-radial from-[#00000059] to-[#FFFFFF1A]"
              onClick={() => navigate('setup')}
            >
              How to run
            </button>
            <button
              className="md:h-[33px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-2 md:px-4 sm:px-3 px-1 border-[#4E00FF] bg-[#8B5CF6] md:w-[122px] w-[80px]"
              onClick={() => navigate('setup')}
            >
              Use Template
            </button>
          </div>
        </div>
        <div className="bg-gradient-radial from-[#00000059] to-[#FFFFFF1A] text-center font-['Poppins'] rounded-[23px] border border-[#848484] max-w-[340px] mx-auto">
          <div className="relative">
            <div className="absolute h-full w-full flex items-center justify-center z-10">
              <div className="flex flex-col items-center">
                <img
                  src={ETHLogo}
                  alt="Bittensor Mining"
                  className="mx-auto w-[25px] h-[40px]"
                />
                <div className="text-center text-[20px] font-semibold clash-display">
                  ETH Validator
                </div>
              </div>
            </div>
            <img src={ETHBg} alt="asd" className="w-full relative" />
          </div>
          <div className="grid justify-center border border-transparent border-b-[#8B5CF6] bg-black py-2 border-opacity-20">
            <div className="text-[10px]">g4dn.16xlarge</div>
            <div className="text-[#555555] text-[11px]">Recommended GPU</div>
          </div>
          <div className="grid justify-center border border-transparent border-b-[#848484] bg-black py-2">
            <div className="text-[10px]">72 Hours</div>
            <div className="text-[#555555] text-[11px]">Recommended Hours</div>
          </div>
          <div className="flex gap-2 justify-center py-3 bg-black rounded-b-[23px]">
            <button className="max-h-[33px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-2 md:px-4 sm:px-3 px-1 border-[#cacacc] bg-black md:w-[122px] w-[80px] bg-gradient-radial from-[#00000059] to-[#FFFFFF1A]">
              How to run
            </button>
            <button className="md:h-[33px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-2 md:px-4 sm:px-3 px-1 border-[#4E00FF] bg-[#8B5CF6] md:w-[122px] w-[80px]">
              Use Template
            </button>
          </div>
        </div>
        <div className="bg-gradient-radial from-[#00000059] to-[#FFFFFF1A] text-center font-['Poppins'] rounded-[23px] border border-[#848484] max-w-[340px] mx-auto">
          <div className="relative">
            <div className="absolute h-full w-full flex items-center justify-center z-10">
              <div className="flex flex-col items-center">
                <div className="text-center text-[24px] font-semibold clash-display">
                  Fine-Tuning
                  <br /> Model
                </div>
              </div>
            </div>
            <img src={FineTuningBg} alt="asd" className="w-full relative" />
          </div>
          <div className="grid justify-center border border-transparent border-b-[#8B5CF6] bg-black py-2 border-opacity-20">
            <div className="text-[10px]">g4dn.16xlarge</div>
            <div className="text-[#555555] text-[11px]">Recommended GPU</div>
          </div>
          <div className="grid justify-center border border-transparent border-b-[#848484] bg-black py-2">
            <div className="text-[10px]">72 Hours</div>
            <div className="text-[#555555] text-[11px]">Recommended Hours</div>
          </div>
          <div className="flex gap-2 justify-center py-3 bg-black rounded-b-[23px]">
            <button className="max-h-[33px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-2 md:px-4 sm:px-3 px-1 border-[#cacacc] bg-black md:w-[122px] w-[80px] bg-gradient-radial from-[#00000059] to-[#FFFFFF1A]">
              How to run
            </button>
            <button className="md:h-[33px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-2 md:px-4 sm:px-3 px-1 border-[#4E00FF] bg-[#8B5CF6] md:w-[122px] w-[80px]">
              Use Template
            </button>
          </div>
        </div>
        <div className="bg-gradient-radial from-[#00000059] to-[#FFFFFF1A] text-center font-['Poppins'] rounded-[23px] border border-[#848484] max-w-[340px] mx-auto">
          <div className="relative">
            <div className="absolute h-full w-full flex items-center justify-center z-10">
              <div className="flex flex-col items-center">
                <div className="text-center text-[20px] font-semibold clash-display">
                  Stable Diffusion <br /> Image Generation
                </div>
              </div>
            </div>
            <img
              src={StableDifussionBg}
              alt="asd"
              className="w-full relative"
            />
          </div>
          <div className="grid justify-center border border-transparent border-b-[#8B5CF6] bg-black py-2 border-opacity-20">
            <div className="text-[10px]">g4dn.16xlarge</div>
            <div className="text-[#555555] text-[11px]">Recommended GPU</div>
          </div>
          <div className="grid justify-center border border-transparent border-b-[#848484] bg-black py-2">
            <div className="text-[10px]">72 Hours</div>
            <div className="text-[#555555] text-[11px]">Recommended Hours</div>
          </div>
          <div className="flex gap-2 justify-center py-3 bg-black rounded-b-[23px]">
            <button className="max-h-[33px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-2 md:px-4 sm:px-3 px-1 border-[#cacacc] bg-black md:w-[122px] w-[80px] bg-gradient-radial from-[#00000059] to-[#FFFFFF1A]">
              How to run
            </button>
            <button className="md:h-[33px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-2 md:px-4 sm:px-3 px-1 border-[#4E00FF] bg-[#8B5CF6] md:w-[122px] w-[80px]">
              Use Template
            </button>
          </div>
        </div>
        <div className="bg-gradient-radial from-[#00000059] to-[#FFFFFF1A] text-center font-['Poppins'] rounded-[23px] border border-[#848484] max-w-[340px] mx-auto">
          <div className="relative">
            <div className="absolute h-full w-full flex items-center justify-center z-10">
              <div className="flex flex-col items-center">
                <div className="text-center text-[20px] font-semibold clash-display">
                  Large Language Model
                  <br /> (LLM) Training
                </div>
              </div>
            </div>
            <img src={LLMBg} alt="asd" className="w-full relative" />
          </div>
          <div className="grid justify-center border border-transparent border-b-[#8B5CF6] bg-black py-2 border-opacity-20">
            <div className="text-[10px]">g4dn.16xlarge</div>
            <div className="text-[#555555] text-[11px]">Recommended GPU</div>
          </div>
          <div className="grid justify-center border border-transparent border-b-[#848484] bg-black py-2">
            <div className="text-[10px]">72 Hours</div>
            <div className="text-[#555555] text-[11px]">Recommended Hours</div>
          </div>
          <div className="flex gap-2 justify-center py-3 bg-black rounded-b-[23px]">
            <button className="max-h-[33px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-2 md:px-4 sm:px-3 px-1 border-[#cacacc] bg-black md:w-[122px] w-[80px] bg-gradient-radial from-[#00000059] to-[#FFFFFF1A]">
              How to run
            </button>
            <button className="md:h-[33px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-2 md:px-4 sm:px-3 px-1 border-[#4E00FF] bg-[#8B5CF6] md:w-[122px] w-[80px]">
              Use Template
            </button>
          </div>
        </div>
        <div className="bg-gradient-radial from-[#00000059] to-[#FFFFFF1A] text-center font-['Poppins'] rounded-[23px] border border-[#848484] max-w-[340px] mx-auto">
          <div className="relative">
            <div className="absolute h-full w-full flex items-center justify-center z-10">
              <div className="flex flex-col items-center">
                <img
                  src={SolanaLogo}
                  alt="Bittensor Mining"
                  className="mx-auto w-[50px] h-[50px]"
                />
                <div className="text-center text-[20px] font-semibold clash-display">
                  Solana Validator
                </div>
              </div>
            </div>
            <img src={SolanaBg} alt="asd" className="w-full relative" />
          </div>
          <div className="grid justify-center border border-transparent border-b-[#8B5CF6] bg-black py-2 border-opacity-20">
            <div className="text-[10px]">g4dn.16xlarge</div>
            <div className="text-[#555555] text-[11px]">Recommended GPU</div>
          </div>
          <div className="grid justify-center border border-transparent border-b-[#848484] bg-black py-2">
            <div className="text-[10px]">72 Hours</div>
            <div className="text-[#555555] text-[11px]">Recommended Hours</div>
          </div>
          <div className="flex gap-2 justify-center py-3 bg-black rounded-b-[23px]">
            <button className="max-h-[33px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-2 md:px-4 sm:px-3 px-1 border-[#cacacc] bg-black md:w-[122px] w-[80px] bg-gradient-radial from-[#00000059] to-[#FFFFFF1A]">
              How to run
            </button>
            <button className="md:h-[33px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-2 md:px-4 sm:px-3 px-1 border-[#4E00FF] bg-[#8B5CF6] md:w-[122px] w-[80px]">
              Use Template
            </button>
          </div>
        </div>
        <div className="bg-gradient-radial from-[#00000059] to-[#FFFFFF1A] text-center font-['Poppins'] rounded-[23px] border border-[#848484] max-w-[340px] mx-auto">
          <div className="relative">
            <div className="absolute h-full w-full flex items-center justify-center z-10">
              <div className="flex flex-col items-center">
                <div className="grid text-center text-[20px] font-">
                  <div className="font-semibold clash-display">
                    AI Inference
                  </div>
                  <div className="font-extralight clash-display">
                    (Natural Language Processing)
                  </div>
                </div>
              </div>
            </div>
            <img src={AIInferenceBg} alt="asd" className="w-full relative" />
          </div>
          <div className="grid justify-center border border-transparent border-b-[#8B5CF6] bg-black py-2 border-opacity-20">
            <div className="text-[10px]">g4dn.16xlarge</div>
            <div className="text-[#555555] text-[11px]">Recommended GPU</div>
          </div>
          <div className="grid justify-center border border-transparent border-b-[#848484] bg-black py-2">
            <div className="text-[10px]">72 Hours</div>
            <div className="text-[#555555] text-[11px]">Recommended Hours</div>
          </div>
          <div className="flex gap-2 justify-center py-3 bg-black rounded-b-[23px]">
            <button className="max-h-[33px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-2 md:px-4 sm:px-3 px-1 border-[#cacacc] bg-black md:w-[122px] w-[80px] bg-gradient-radial from-[#00000059] to-[#FFFFFF1A]">
              How to run
            </button>
            <button className="md:h-[33px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-2 md:px-4 sm:px-3 px-1 border-[#4E00FF] bg-[#8B5CF6] md:w-[122px] w-[80px]">
              Use Template
            </button>
          </div>
        </div>
        <div className="bg-gradient-radial from-[#00000059] to-[#FFFFFF1A] text-center font-['Poppins'] rounded-[23px] border border-[#848484] max-w-[340px] mx-auto">
          <div className="relative">
            <div className="absolute h-full w-full flex items-center justify-center z-10">
              <div className="flex flex-col items-center">
                <img
                  src={BitcoinLogo}
                  alt="Bittensor Mining"
                  className="mx-auto w-[54px] h-[54px]"
                />
                <div className="text-center text-[20px] font-semibold clash-display">
                  Bitcoin Lightning Node
                </div>
              </div>
            </div>
            <img src={BitcoinBg} alt="asd" className="w-full relative" />
          </div>
          <div className="grid justify-center border border-transparent border-b-[#8B5CF6] bg-black py-2 border-opacity-20">
            <div className="text-[10px]">g4dn.16xlarge</div>
            <div className="text-[#555555] text-[11px]">Recommended GPU</div>
          </div>
          <div className="grid justify-center border border-transparent border-b-[#848484] bg-black py-2">
            <div className="text-[10px]">72 Hours</div>
            <div className="text-[#555555] text-[11px]">Recommended Hours</div>
          </div>
          <div className="flex gap-2 justify-center py-3 bg-black rounded-b-[23px]">
            <button className="max-h-[33px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-2 md:px-4 sm:px-3 px-1 border-[#cacacc] bg-black md:w-[122px] w-[80px] bg-gradient-radial from-[#00000059] to-[#FFFFFF1A]">
              How to run
            </button>
            <button className="md:h-[33px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-2 md:px-4 sm:px-3 px-1 border-[#4E00FF] bg-[#8B5CF6] md:w-[122px] w-[80px]">
              Use Template
            </button>
          </div>
        </div>
        <div className="bg-gradient-radial from-[#00000059] to-[#FFFFFF1A] text-center font-['Poppins'] rounded-[23px] border border-[#848484] max-w-[340px] mx-auto">
          <div className="relative">
            <div className="absolute h-full w-full flex items-center justify-center z-10">
              <div className="flex flex-col items-center">
                <div className="text-center text-[24px] font-semibold clash-display">
                  Decentralized Storage <br /> Node{' '}
                  <span className="font-extralight">(IPFS)</span>
                </div>
              </div>
            </div>
            <img src={IPFSBg} alt="asd" className="w-full relative" />
          </div>
          <div className="grid justify-center border border-transparent border-b-[#8B5CF6] bg-black py-2 border-opacity-20">
            <div className="text-[10px]">g4dn.16xlarge</div>
            <div className="text-[#555555] text-[11px]">Recommended GPU</div>
          </div>
          <div className="grid justify-center border border-transparent border-b-[#848484] bg-black py-2">
            <div className="text-[10px]">72 Hours</div>
            <div className="text-[#555555] text-[11px]">Recommended Hours</div>
          </div>
          <div className="flex gap-2 justify-center py-3 bg-black rounded-b-[23px]">
            <button className="max-h-[33px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-2 md:px-4 sm:px-3 px-1 border-[#cacacc] bg-black md:w-[122px] w-[80px] bg-gradient-radial from-[#00000059] to-[#FFFFFF1A]">
              How to run
            </button>
            <button className="md:h-[33px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-2 md:px-4 sm:px-3 px-1 border-[#4E00FF] bg-[#8B5CF6] md:w-[122px] w-[80px]">
              Use Template
            </button>
          </div>
        </div>
        <div className="bg-gradient-radial from-[#00000059] to-[#FFFFFF1A] text-center font-['Poppins'] rounded-[23px] border border-[#848484] max-w-[340px] mx-auto">
          <div className="relative">
            <div className="absolute h-full w-full flex items-center justify-center z-10">
              <div className="flex flex-col items-center">
                <div className="text-center text-[20px] font-semibold clash-display">
                  AI Model Pruning
                </div>
              </div>
            </div>
            <img src={AIPruningBg} alt="asd" className="w-full relative" />
          </div>
          <div className="grid justify-center border border-transparent border-b-[#8B5CF6] bg-black py-2 border-opacity-20">
            <div className="text-[10px]">g4dn.16xlarge</div>
            <div className="text-[#555555] text-[11px]">Recommended GPU</div>
          </div>
          <div className="grid justify-center border border-transparent border-b-[#848484] bg-black py-2">
            <div className="text-[10px]">72 Hours</div>
            <div className="text-[#555555] text-[11px]">Recommended Hours</div>
          </div>
          <div className="flex gap-2 justify-center py-3 bg-black rounded-b-[23px]">
            <button className="max-h-[33px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-2 md:px-4 sm:px-3 px-1 border-[#cacacc] bg-black md:w-[122px] w-[80px] bg-gradient-radial from-[#00000059] to-[#FFFFFF1A]">
              How to run
            </button>
            <button className="md:h-[33px] md:text-[12px] sm:text-[10px] text-[8px] font-['Poppins'] border rounded-[8px] lg:px-2 md:px-4 sm:px-3 px-1 border-[#4E00FF] bg-[#8B5CF6] md:w-[122px] w-[80px]">
              Use Template
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Templates;
