// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: 'ClashDisplay';
}

.clash-display {
  font-family: 'ClashDisplay';
}
button {
  -webkit-user-select: none;
          user-select: none;
}

html,
body {
  scrollbar-color: red yellow;
  scrollbar-color: #8b5cf6 #0a0010;
  overflow: overlay;
  scrollbar-track-color: #8b5cf6;
}
.text-button {
  -webkit-user-select: none;
          user-select: none;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B;AAC7B;AACA;EACE,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;;EAEE,2BAA2B;EAC3B,gCAAgC;EAChC,iBAAiB;EACjB,8BAA8B;AAChC;AACA;EACE,yBAAiB;UAAjB,iBAAiB;AACnB","sourcesContent":["body {\n  font-family: 'ClashDisplay';\n}\n\n.clash-display {\n  font-family: 'ClashDisplay';\n}\nbutton {\n  user-select: none;\n}\n\nhtml,\nbody {\n  scrollbar-color: red yellow;\n  scrollbar-color: #8b5cf6 #0a0010;\n  overflow: overlay;\n  scrollbar-track-color: #8b5cf6;\n}\n.text-button {\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
