import React, { createContext, useState, ReactNode } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import type UserContextType from '../interfaces/auth-context';
import User from '../interfaces/user';

const dummyUser = {
  name: '',
  email: '',
  id: null,
  createdAt: '',
  deletedAt: null,
  phoneNumber: '',
  roleId: 1,
  teamId: 2,
  updatedAt: '',
  username: '',
  stripeCustomerId: null,
  stripePaymentMethodId: null,
  teamName: '',
  isInvitationAccepted: false,
  lastLogin: '',
  role: '',
  credits: 0,
};

export const UserContext = createContext<UserContextType>({
  user: dummyUser,
  setUser: () => {},
  loading: true,
  setLoading: () => {},
  getUser: () => {},
});

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User>(dummyUser);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('authToken');
  const navigate = useNavigate();

  const getUser = async () => {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_API_URL + '/users/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUser(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        localStorage.removeItem('authToken');
        navigate('/login');
        setLoading(false);
      });
  };

  return (
    <UserContext.Provider
      value={{ user, setUser, loading, setLoading, getUser }}
    >
      {children}
    </UserContext.Provider>
  );
};
